import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import Input from "../../../../../components/form/Input";
import { Filter } from "./Types";
import { useClickAway, useHoverDirty } from "react-use";

type DiscoverFiltersMenuProps = {
    filters: Filter[];
    setFilterToShow: (filter: Filter | null) => void;
    onClose: () => void;
}

export const DiscoverFiltersMenu = ({ filters, setFilterToShow, onClose }: DiscoverFiltersMenuProps) => {
    const [input, setInput] = useState('');
    const ref = useRef<HTMLDivElement>(null);
    const [highlightedFilter, setHighlightedFilter] = useState<Filter>(filters[0]);
    useClickAway(ref, onClose);
    const [lastKeyPressed, setLastKeyPressed] = useState<string>('');

    // capture key presses
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            setLastKeyPressed(e.key);
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    });

    const onFilterClick = useCallback((filter: Filter) => {
        setFilterToShow(filter);
        onClose();
    }, [onClose, setFilterToShow]);

    // on key press change
    useEffect(() => {
        const highlightedFilterIndex = filters.indexOf(highlightedFilter);
        switch (lastKeyPressed) {
            case 'Enter':
                onFilterClick(highlightedFilter);
                break;
            case 'ArrowDown':
                if (highlightedFilterIndex < filters.length - 1) {
                    setHighlightedFilter(filters[highlightedFilterIndex + 1]);
                }
                break;
            case 'ArrowUp':
                if (highlightedFilterIndex > 0) {
                    setHighlightedFilter(filters[highlightedFilterIndex - 1]);
                }
                break;
            case 'Escape':
                onClose();
                break;
        }
        setLastKeyPressed('');
    }, [lastKeyPressed, filters, highlightedFilter, onClose, onFilterClick, setHighlightedFilter]);

    const filteredFilters = filters.filter((f) => f.name.toLowerCase().includes(input.toLowerCase()));

    return (
        <div className="absolute -top-4 w-60 z-30 rounded-md border border-slate-200 bg-white shadow text-tertiary" ref={ref}>
            <div className="m-1">
                <Input
                    placeholder="Search filters"
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
                    value={input}
                    icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
                    height="h-8"
                    background="bg-slate-100"
                    border="border-slate-200"
                    shadow=""
                    rounded="rounded-md"
                    highlightBorder=""
                    focus={true}
                />
            </div>
            <div className="my-2">
                {filteredFilters.map((f) => (
                    <FilterOption 
                        key={f.name}
                        filter={f}
                        onClick={onFilterClick}
                        isHighlighted={highlightedFilter === f}
                        setHighlightedFilter={setHighlightedFilter}
                    />
                ))}
            </div>
        </div>
    );
};

type FilterOptionProps = {
    filter: Filter;
    onClick: (filter: Filter) => void;
    isHighlighted: boolean;
    setHighlightedFilter: (filter: Filter) => void;
}

const FilterOption = ({ filter, onClick, isHighlighted, setHighlightedFilter }: FilterOptionProps) => {
    const optionRef = useRef<HTMLDivElement>(null);
    const isHovering = useHoverDirty(optionRef);
    if (isHovering && !isHighlighted) {
        setHighlightedFilter(filter);
    }
    return (
        <div
            key={filter.name}
            className={`flex items-center gap-1 cursor-pointer text-tertiary py-1 px-2 ${isHighlighted ? 'bg-slate-100' : ''}`}
            onClick={() => onClick(filter)}
            ref={optionRef}
        >
            {filter.menuIcon}
            <span className="text-text-primary">{filter.name}</span>
        </div>
    );
};