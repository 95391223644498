import { useGetMetricsDependenciesQuery } from "../../../../../../services/nodes/nodes";
import { NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../../types";
import { useEffect, useMemo } from "react";
import { BackendErrorType, extractErrorMessage } from "../../../../../../services/api";
import { notify } from "../../../../../../components/Toaster";
import { AggregateTableGroupEntities } from "./AggregateTableGroupEntities";
import { AggregateTableGroupDimensions } from "./AggregateTableGroupDimensions";

type AggregateTableGroupFormProps = {
    projectId: number;
    changeData: NewAggregateTableChangeData;
    setChangeData: (changeData: NewAggregateTableChangeData) => void;
    setCompilationError: (error: string) => void;
}

export const AggregateTableGroupForm = ({ projectId, changeData, setChangeData, setCompilationError }: AggregateTableGroupFormProps) => {
    const columns = useMemo(() => changeData.columns, [changeData]);
    const metrics = useMemo(() => columns.filter(c => c.type === NewAggregateTableChangeColumnType.metric), [columns]);
    const getMetricDependencies = useGetMetricsDependenciesQuery({ projectId, metrics: metrics.map(m => m.name) }, { skip: metrics.length === 0 });
    const metricDependencies = useMemo(() => metrics.length ? getMetricDependencies.data || [] : [], [getMetricDependencies.data, metrics]);
    const isLoading = getMetricDependencies.isLoading;

    useEffect(() => {
        if (getMetricDependencies.error) {
            const extractedError = extractErrorMessage(getMetricDependencies.error);
            if (extractedError.type === BackendErrorType.MetricFlow) {
                setCompilationError(extractedError.message);
            }
            else {
                notify(extractedError.message, 'error');
            }
        }
    }, [getMetricDependencies.error, setCompilationError]);

    return (
        <div className="flex flex-col gap-2">
            <div className="flex mt-2">
                <div className="text-secondary text-sm font-medium w-24">Entity</div>
                <AggregateTableGroupEntities metricDependencies={metricDependencies} isLoading={isLoading} changeData={changeData} setChangeData={changeData => setChangeData(changeData)} />
            </div>
            <div className="flex mt-2">
                <div className="text-secondary text-sm font-medium w-24">Dimension</div>
                <AggregateTableGroupDimensions metricDependencies={metricDependencies} isLoading={isLoading} changeData={changeData} setChangeData={changeData => setChangeData(changeData)} />
            </div>
        </div>
    );
};