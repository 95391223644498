import { NodeUsageAppType } from "../../../services/nodes/types";

export enum Swimlane {
    application = 'application',
    transformation = 'transformation',
    sources = 'sources',
    appModeling = 'app modeling',
}

export type IMeta = {
    [key: string]: string;
}

export enum NodeType {
    DataModel = 'Model',
    DataSource = 'Source',
    LookerLook = 'LookerLook',
    LookerView = 'LookerView',
    LookerExplore = 'LookerExplore',
    Orphan = 'Table',
    LookerDashboard = 'LookerDashboard',
    LookerTile = 'LookerTile',
    LookerDerivedView = 'LookerDerivedView',
    TableauWorkbook = 'TableauWorkbook',
    TableauView = 'TableauView',
    TableauCustomQuery = 'TableauCustomQuery',
    TableauPublishedDataSource = 'TableauPublishedDataSource',
    TableauEmbeddedDataSource = 'TableauEmbeddedDataSource',
    TableauDashboard = 'TableauDashboard',
    TableauStory = 'TableauStory'
}

export enum SubnodeType {
    Dimension = 'Dimension',
    Measure = 'Measure',
    Entity = 'Entity',
    Metric = 'RelatedMetric',
    CustomField = 'CustomField',
    Schema = 'Schema',
    Column = 'Column',
    TableCalculation = 'TableCalculation',
    LookerConnectedView = 'LookerConnectedView',
    LookerTile = 'LookerTile',
    LookerLook = 'LookerLook',
    TableauConnectedView = 'TableauConnectedView',
}

export const nodeNameMap = new Map<NodeType, string>([
    [NodeType.DataModel, 'Data Model'],
    [NodeType.DataSource, 'Data Source'],
    [NodeType.Orphan, 'Table'],
    [NodeType.LookerView, 'View'],
    [NodeType.LookerLook, 'Look'],
    [NodeType.LookerExplore, 'Explore'],
    [NodeType.LookerDashboard, 'Dashboard'],
    [NodeType.LookerTile, 'Tile'],
    [NodeType.LookerDerivedView, 'Derived View'],
    [NodeType.TableauWorkbook, 'Workbook'],
    [NodeType.TableauView, 'View'],
    [NodeType.TableauCustomQuery, 'Custom Query'],
    [NodeType.TableauPublishedDataSource, 'Published Data Source'],
    [NodeType.TableauEmbeddedDataSource, 'Embedded Data Source'],
    [NodeType.TableauDashboard, 'Dashboard'],
    [NodeType.TableauStory, 'Story'],
]);

export const subnodeNameMap = new Map<SubnodeType, string>([
    [SubnodeType.Dimension, 'Dimension'],
    [SubnodeType.Measure, 'Measure'],
    [SubnodeType.Entity, 'Entity'],
    [SubnodeType.Metric, 'Related Metric'],
    [SubnodeType.CustomField, 'Custom Field'],
    [SubnodeType.Schema, 'Schema'],
    [SubnodeType.Column, 'Column'],
    [SubnodeType.TableCalculation, 'Table Calculation'],
    [SubnodeType.LookerConnectedView, 'Connected View'],
    [SubnodeType.LookerTile, 'Tile'],
    [SubnodeType.LookerLook, 'Look'],
    [SubnodeType.TableauConnectedView, 'Connected View'],
]);


export type IExpandedNode = {
    subnodes: ISubnode[];
    lastAccessedAt?: string;
    favouriteCount?: number;
    url?: string;
    lastUpdatedAt?: string;
    lastUpdatedBy: string | null;
    createdAt?: string;
    createdBy: string | null;
    withCode: boolean;
    id: string;
    name: string;
    type: NodeType;
    description: string;
    tags?: string[];
    meta?: IMeta;
    generatedByDelphi?: boolean;
    materialization?: string;
    package?: string;
    database?: string;
    databaseSchema?: string;
    identifier?: string;
    parentName: string;
    repo?: string;
    branch?: string;
    rawCode?: string;
    compiledCode?: string;
    semanticCode?: string;
    userAllowedToPromote: boolean;
    sourceDirectory?: string;
    lookerModel?: string;
    lookerFolder?: string;
    lookerProject?: string;
    proposalId: number | null;
    usage: INodeUsage | null;
    last30DaysViews: number | null;
    last7DaysViews: number | null;
    dbtProjectName: string | null;
    eunoProjectId: number | null;
    typeSpecificInfo: { [key: string]: string };
    containedNodes: IContainedNode[];
    chainedNodes: IChainedNode[];
}

export type ISubnode = {
    name: string;
    description: string;
    type: SubnodeType;
    subType: string;
    tags: string[];
    meta: IMeta;
    withCode: boolean;
    parentName: string;
    proposalId: number | null;
    rawCode: string;
    compiledCode: string;
    semanticCode: string;
    url: null;
    id: string;
    usage: INodeUsage | null;
    last30DaysViews: number | null;
    last7DaysViews: number | null;
    typeSpecificInfo: { [key: string]: string };
}

export type IContainedNode = {
    name: string;
    type: NodeType;
    id: string;
}

export type IChainedNode = {
    name: string;
    type: NodeType;
    id: string;
}

export type INodeUsage = {
    usage14Days?: number;
    sources: ISourceNodeUsage[];
    users: IUserNodeUsage[];
}

export type ISourceNodeUsage = {
    utl: string | null;
    title: string;
    number: number;
    type: NodeUsageAppType;
    dashboardTitle: string | null;
}

export type IUserNodeUsage = {
    name: string;
    number: number;
    email: string;
}

export interface ISuperficialNode {
    id: string;
    name: string;
    swimlane: Swimlane;
    parents: string[];
    type: NodeType;
    description?: string;
    tags?: string[];
    meta?: IMeta;
    generatedByDelphi?: boolean;
    proposals?: boolean;
    materialization?: string;
    last30DaysViews?: number;
    last7DaysViews?: number;
    package?: string;
    database?: string;
    databaseSchema?: string;
    identifier?: string;
    parentName?: string;
    repo?: string;
    branch?: string;
    numberOfDimensions?: number;
    numberOfMeasures?: number;
    numberOfEntities?: number;
    numberOfMetrics?: number;
    numberOfCustomFields?: number;
    numberOfColumns?: number;
    subnodes: ISuperficialSubnode[];
    hasDownstreamNodes: boolean;
    hasUpstreamNodes: boolean;
    owner: string | null;
}

export interface ISuperficialSubnode {
    name: string;
    description: string;
    type: SubnodeType;
}

export const nodeWidth = 256;
export const nodeHeight = 26.5;
export const distanceBetweenNodes = 60;

export enum CodeType {
    Raw = 'rawCode',
    Compiled = 'compiledCode',
    Semantic = 'semanticCode',
}

export const swimlaneOrder = [Swimlane.sources, Swimlane.transformation, Swimlane.appModeling, Swimlane.application];
export const dagNodeTypeOrder = [NodeType.Orphan, NodeType.DataSource, NodeType.DataModel, NodeType.LookerView, NodeType.LookerDerivedView, NodeType.LookerExplore, NodeType.LookerLook, NodeType.LookerTile, NodeType.LookerDashboard];

export type MetricDependency = {
    entityName: string,
    entityPaths: string[],
    entityDescription: string | null,
    dimensions: {
        name: string,
        description: string | null,
        type: 'categorical' | 'time';
    }[],
}

export const subnodeTypePlurals = {
    [SubnodeType.Dimension]: 'Dimensions',
    [SubnodeType.Measure]: 'Measures',
    [SubnodeType.CustomField]: 'Custom Fields',
    [SubnodeType.Entity]: 'Entities',
    [SubnodeType.Metric]: 'Related Metrics',
    [SubnodeType.Schema]: 'Schema',
    [SubnodeType.Column]: 'Columns',
    [SubnodeType.TableCalculation]: 'Table Calculations',
    [SubnodeType.LookerConnectedView]: 'Connected Views',
    [SubnodeType.LookerLook]: 'Looks',
    [SubnodeType.LookerTile]: 'Tiles',
    [SubnodeType.TableauConnectedView]: 'Connected Views',
};

export const nodeTypePlurals = {
    [NodeType.DataModel]: 'Data Models',
    [NodeType.DataSource]: 'Data Sources',
    [NodeType.Orphan]: 'Tables',
    [NodeType.LookerView]: 'Views',
    [NodeType.LookerDerivedView]: 'Derived Views',
    [NodeType.LookerExplore]: 'Explores',
    [NodeType.LookerLook]: 'Looks',
    [NodeType.LookerDashboard]: 'Dashboards',
    [NodeType.LookerTile]: 'Tiles',
    [NodeType.TableauWorkbook]: 'Workbooks',
    [NodeType.TableauView]: 'Views',
    [NodeType.TableauCustomQuery]: 'Custom Queries',
    [NodeType.TableauPublishedDataSource]: 'Published Data Sources',
    [NodeType.TableauEmbeddedDataSource]: 'Embedded Data Sources',
    [NodeType.TableauDashboard]: 'Dashboards',
    [NodeType.TableauStory]: 'Stories',
};

export const isNodeHiddenFromDag = (node: ISuperficialNode) => {
    const hiddenNodeTypes = [NodeType.TableauWorkbook];
    return hiddenNodeTypes.includes(node.type);
};