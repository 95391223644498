import { useGetProjectBuildsQuery } from '../../../services/projects/projects';
import PageLoader from '../../../components/loaders/PageLoader';
import { IProject } from '../IProject';
import Table from '../../../components/Table/Table';
import dateformat from 'dateformat';
import BuildStatusLabel from '../../builds/BuildStatusLabel';
import BuildLifecycleState from '../../builds/BuildLifecycleState';
import { EyeIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import IBuild from '../../builds/IBuild';
import getDataSyncStatus from '../../builds/getDataSyncStatus';
import BuildSyncStatusLabel from '../../builds/BuildSyncStatusLabel';
import Modal from '../../../components/Modal/Modal';
import { ButtonTypes } from '../../../components/button/types';
import OperationLogsContainer from '../../operations/OperationLogsContainer';
import { OperationStatus } from '../../operations/Operation';
import OperationStatusLabel from '../../operations/OperationStatusLabel';

const ManageProjectBuilds = ({ project }: { project: IProject }) => {
  const [showBuildLogs, setShowBuildLogs] = useState(false);
  const [buildForBuildLogs, setBuildForBuildLogs] = useState<IBuild | null>(null);
  const { data: builds = [], isLoading: isLoadingBuilds } = useGetProjectBuildsQuery({ id: project.id });

  const tableColumns = [
    {
      name: 'TIMESTAMP',
      selector: (row: unknown) => (
        <span className="font-medium">
          {(row as IBuild).buildTime ? dateformat(`${(row as IBuild).buildTime}`, 'mmm dd, HH:MM') : 'Running'}
        </span>
      )
    },
    {
      name: 'REVISION',
      selector: (row: unknown) => `${(row as IBuild).revisionSha}`
    },
    {
      name: 'STATUS',
      selector: (row: unknown) => (
        <div className="flex items-center">
          <BuildStatusLabel status={(row as IBuild).lifecycleState as BuildLifecycleState} />
          <EyeIcon
            className="ml-4 inline-block h-5 w-5 cursor-pointer text-slate-400"
            onClick={() => {
              setShowBuildLogs(true);
              setBuildForBuildLogs(row as IBuild);
            }}
          />
        </div>
      )
    },
    {
      name: 'DATA SYNC',
      selector: (row: unknown) => {
        const build = row as IBuild;
        const dataSyncStatus = getDataSyncStatus(build);
        return <BuildSyncStatusLabel status={dataSyncStatus} />;
      }
    }
  ];

  if (isLoadingBuilds) {
    return <PageLoader />;
  }

  return (
    <div className="mt-3">
      <span className="text-lg text-text-primary">Builds</span>
      <div className="mt-4">
        <Table data={builds} columns={tableColumns} />
      </div>
      {buildForBuildLogs && buildForBuildLogs.operationId && (
        <BuildLogsModal isOpen={showBuildLogs} onClose={() => setShowBuildLogs(false)} build={buildForBuildLogs} />
      )}
    </div>
  );
};

interface BuildLogsModalProps {
  isOpen: boolean;
  onClose: () => void;
  build: IBuild;
}

const BuildLogsModal = ({ isOpen, onClose, build }: BuildLogsModalProps) => {
  const [operationId, setOperationId] = useState<number>(build?.operationId || 0);
  const operations: {
    title: string;
    operationId: number | null;
    status: BuildLifecycleState | OperationStatus;
    type: 'build' | 'sync';
  }[] = [
      {
        title: 'build log',
        status: build.lifecycleState,
        operationId: build.operationId,
        type: 'build'
      },
      ...build.dataSyncRuns.map((dataSyncRun) => ({
        title: 'data sync',
        status: dataSyncRun.status,
        operationId: dataSyncRun.operationId,
        type: 'sync' as 'build' | 'sync'
      }))
    ];
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Viewing build log from ${build.buildTime ? dateformat(`${build.buildTime}`, 'mmm dd, HH:MM') : 'now'}`}
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose }]}
      maxWidth="max-w-2xl">
      <div className="flex h-64 gap-4">
        <div className="flex flex-col gap-1">
          {operations.map((operation, index) => {
            const isSelected = operation.operationId === operationId;
            const className = isSelected ? 'border border-slate-200 bg-surface-light' : 'border border-transparent';
            return (
              <div
                className={`${className} flex cursor-pointer items-center rounded-lg px-2 py-3 hover:bg-surface-light`}
                key={index}
                onClick={() => setOperationId(operation.operationId || 0)}>
                <div className="text-md font-medium text-text-primary max-w-[6rem] overflow-hidden text-ellipsis">{build.branchNames.join(', ')}</div>
                <div className="overflow-none ml-2 whitespace-nowrap text-sm text-slate-500">{operation.title}</div>
                <div className="ml-6 text-sm">
                  {operation.type === 'build' ? (
                    <BuildStatusLabel status={operation.status as BuildLifecycleState} />
                  ) : (
                    <OperationStatusLabel status={operation.status as OperationStatus} />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <OperationLogsContainer operationId={operationId || 0} />
      </div>
    </Modal>
  );
};

export default ManageProjectBuilds;
