import { CodeBracketIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { TextWithEllipsisAndTooltip } from "../../../components/TextWithEllipsisAndTooltip";

type MetaSectionProps = {
  meta: { key: string; value: string }[];
}

export const MetaSection = ({ meta }: MetaSectionProps) => {
  const [showMoreMeta, setShowMoreMeta] = useState(false);
  const nonEunoMeta = meta.filter( m => !m.key.includes('euno.ai/'));
  const withMeta = nonEunoMeta.length > 0;
  return (
    <div>
      <div className="mt-3 flex items-center gap-1">
        <CodeBracketIcon width="16" height="16" />
        Meta
      </div>
      <div className="mt-1 text-sm">
        {
          withMeta ? (
            <>
              {nonEunoMeta.slice(0, showMoreMeta ? undefined : 3).map(({ key, value }) => (
                <div className="flex gap-2 overflow-hidden" key={key}>
                  <div className="w-24">
                    <TextWithEllipsisAndTooltip text={key} maxChars={14} />
                  </div>
                  <TextWithEllipsisAndTooltip text={value} maxChars={34} />
                </div>
              ))}
              {nonEunoMeta.length > 3 && (
                <div className="text-sm font-medium text-tertiary" onClick={() => setShowMoreMeta(!showMoreMeta)}>
                  {showMoreMeta ? 'Show less' : 'Show more'}
                </div>
              )}
            </>
          ) : 'No meta'
        }
      </div>
    </div>
  );
};