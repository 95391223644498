import { Bars4Icon } from "@heroicons/react/24/outline";
import { DiscoverFiltersBar } from "./filters/DiscoverFiltersBar";
import { DiscoverSearch } from "./DiscoverSearch";
import { DiscoverView, SetSelectedSubNode } from "../types";
import { DataModelIcon } from "../../../../assets/images/icons/DelphiIcons";
import { ISuperficialNode } from "../INode";
import { ExpandedNodesBadges } from "./ExpandedNodesBadges";
import { ExpandedNode } from "../view/DAG/types";

type DiscoverToolbarProps = {
    view: DiscoverView;
    setView: (view: DiscoverView) => void;
    setSelectedNode: (node: ISuperficialNode | null) => void;
    setFilterValues: (values: string[]) => void;
    setSelectedSubNode: SetSelectedSubNode;
    expandedNodes: ExpandedNode[];
    setExpandedNodes: (expandedNodes: ExpandedNode[]) => void;
}

export const DiscoverToolbar = ({
    view,
    setView,
    setSelectedNode,
    setFilterValues,
    setSelectedSubNode,
    expandedNodes,
    setExpandedNodes
}: DiscoverToolbarProps) => {
    return (
        <div className="flex justify-between items-center bg-white px-4 h-14 border-b border-slate-200" id="discoverToolbar">
            <DiscoverFiltersBar setFilterValues={setFilterValues} />
            <div className="flex gap-2 items-center">
                {
                    view === DiscoverView.Graph && (
                        <>
                            <ExpandedNodesBadges expandedNodes={expandedNodes} setExpandedNodes={setExpandedNodes} />
                            <DiscoverSearch setSelectedNode={setSelectedNode} setSelectedSubNode={setSelectedSubNode} />
                        </>
                    )
                }
                <div className='w-fit rounded bg-surface-light p-0.5 flex items-center'>
                    {
                        [DiscoverView.Table, DiscoverView.Graph].map((v) => (
                            <div key={v} data-test-id={v} className={`rounded p-2 cursor-pointer hover:bg-white ${view === v && 'bg-white'}`} onClick={() => setView(v)}>
                                {v === DiscoverView.Table ? <Bars4Icon width="16" height="16" className={view === v ? "text-surface-primary" : "text-slate-400"} /> : <DataModelIcon width="12" height="12" fill={view === v ? "#0047FF" : "grey"} />}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};