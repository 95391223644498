import { Tooltip } from "react-tooltip";
import { getNodeUrl } from "../getNodeUrl";
import { useNavigate } from "react-router-dom";
import { ViewfinderCircleIcon } from "@heroicons/react/24/outline";

export const IsolateNodeButton = ({ utl }: { utl: string }) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(getNodeUrl(utl));
    };
    return (
        <div>
            <ViewfinderCircleIcon id="isolate-selection-button" onClick={onClick} width={12} height={12} className='focus:outline-none cursor-pointer color-slate-300 hover:color-text-tertiary' />
            <Tooltip anchorSelect="#isolate-selection-button" className="!p-1 !text-sm">Isolate selection</Tooltip>
        </div>
    );
};