import { CollapseRightIcon } from "../../../../../assets/images/icons/DelphiIcons";
import Sidepane from "../../../../../components/Sidepane/Sidepane";
import { NodeType } from "../../INode";
import { NodeSidepane } from "./NodeSidepane";
import PageLoader from "../../../../../components/loaders/PageLoader";
import { useGetExpandedNodeQuery } from "../../../../../services/nodes/nodes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { useEffect, useState } from "react";
import { ModelSidepane } from "./dbt/ModelSidepane";
import { notify } from "../../../../../components/Toaster";
import { TableauViewSidepane } from "./Tableau/TableauViewSidepane";
import { TableauDashboardSidepane } from "./Tableau/TableauDashboardSidepane";
import { TableauEmbeddedDataSourceSidepane } from "./Tableau/TableauEmbeddedDataSourceSidepane";
import { TableauStorySidepane } from "./Tableau/TableauStorySidepane";

type NodeSidepaneLoaderProps = {
    nodeId: string;
    isOpen: boolean;
    onClose: () => void;
}

export const NodeSidepaneLoader = ({ nodeId, isOpen, onClose }: NodeSidepaneLoaderProps) => {
    const [canCloseModal, setCanCloseModal] = useState<boolean>(true);
    const accountId = useSelector(selectActiveAccountId);
    const getExpandedNode = useGetExpandedNodeQuery({ accountId, nodeId }, { skip: !nodeId });
    const expandedNode = getExpandedNode.data;
    const NodeSidepaneComponent = getSidepaneComponentByType(expandedNode?.type || null);
    const isLoading = nodeId !== expandedNode?.id;
    const onCloseClick = () => canCloseModal && onClose();
    
    useEffect(() => {
        if (getExpandedNode.error) {
            notify('Failed to load node', 'error');
            console.error(getExpandedNode.error);
        }
    }, [getExpandedNode.error]);

    return (
        <Sidepane isOpen={isOpen} onClose={onCloseClick}>
            <div className="w-[22rem] h-full flex flex-col">
                <div className="w-fit cursor-pointer p-1" onClick={onCloseClick}>
                    <CollapseRightIcon width="16" height="16" fill="#94A3B8" />
                </div>
                {
                    isLoading && <PageLoader />
                }
                {
                    !isLoading && nodeId && expandedNode && <NodeSidepaneComponent setCanCloseModal={setCanCloseModal} node={expandedNode} />
                }
            </div>
        </Sidepane>
    );
};

const getSidepaneComponentByType = (type: NodeType | null) => {
    switch (type) {
        case NodeType.DataModel:
            return ModelSidepane;
        case NodeType.TableauView:
            return TableauViewSidepane;
        case NodeType.TableauDashboard:
            return TableauDashboardSidepane;
        case NodeType.TableauEmbeddedDataSource:
            return TableauEmbeddedDataSourceSidepane;
        case NodeType.TableauStory:
            return TableauStorySidepane;
        default:
            return NodeSidepane;
    }
};