import { IChange, NewMeasureChangeData } from '../../IChange';
import { Implications } from './NewMeasureImplications';
import { TagsForm } from '../../formSections/TagsForm';
import { MetaForm } from '../../formSections/MetaForm';
import { DescriptionForm } from '../../formSections/DescriptionForm';
import { NameForm } from '../../formSections/NameForm';

interface NewMeasureFormProps {
  changeData: NewMeasureChangeData;
  setChangeData: (changeData: NewMeasureChangeData) => void;
  proposal: IChange;
}

const NewMeasureForm = ({ changeData, setChangeData, proposal }: NewMeasureFormProps) => {
  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex items-center gap-2 mb-2">
        <div className="text-secondary text-sm font-medium w-24">Model changes</div>
        <Implications proposal={proposal} />
      </div>
      <NameForm label="Measure" name={changeData.newMeasureName} setName={newMeasureName => setChangeData({ ...changeData, newMeasureName })} />
      <DescriptionForm label="Measure" description={changeData.description} setDescription={description => setChangeData({ ...changeData, description })} />
      <TagsForm label="Measure" tags={changeData.tags} setTags={tags => setChangeData({ ...changeData, tags })} />
      <MetaForm label="Measure" meta={changeData.meta} setMeta={meta => setChangeData({ ...changeData, meta })} />
    </div>
  );
};

export default NewMeasureForm;
