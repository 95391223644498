import Modal from '../../components/Modal/Modal';
import { ButtonTypes } from '../../components/button/types';
import Input from '../../components/form/Input';
import React, { useCallback, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { useCreateProjectMutation } from '../../services/projects/projects';
import { extractErrorMessage } from '../../services/api';
import { notify } from '../../components/Toaster';
import FormError from '../../components/form/FormError';
import { useGetRepositoriesQuery } from '../../services/users';
import Select, { Option } from '../../components/form/Select';
import repositoryProviders from './repositoryProviders';
import { useGetRepositoryBranchesQuery } from '../../services/repositories';
import { BranchIcon } from '../../assets/images/icons/DelphiIcons';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import IRepository from './IRepository';
import getProjectOrganization from './getProjectOrganization';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProjectModal = ({ isOpen, onClose }: CreateProjectModalProps) => {
  const [projectName, setProjectName] = useState('');
  const [dbtProjectPath, setDptProjectPath] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [branch, setBranch] = useState<string>('');
  const [repositoryId, setRepositoryId] = useState<number | null>(null);
  const [repository, setRepository] = useState<IRepository | null>(null);
  const [createProject, { isLoading }] = useCreateProjectMutation();
  const repositoriesQuery = useGetRepositoriesQuery(undefined, { skip: !isOpen });
  const repositories = useMemo(() => repositoriesQuery.data || [], [repositoriesQuery.data]);
  const branchesQuery = useGetRepositoryBranchesQuery(
    { repository: repository?.name || '', organization: getProjectOrganization(repository?.cloneUrl || '') },
    {
      skip: !repository
    }
  );
  const accountId = useSelector(selectActiveAccountId);

  useEffect(() => {
    setErrorMessage('');
  }, [projectName, repositoryId, branch]);

  useEffect(() => {
    setBranch('');
    setRepository(repositories.find((r) => r.id === repositoryId) || null);
  }, [repositoryId, repositories]);

  useEffect(() => {
    if (isOpen && repositoriesQuery.error) {
      notify(`Error loading repositories: ${extractErrorMessage(repositoriesQuery.error).message}`, 'error');
      onClose();
    }
  }, [repositoriesQuery.error, isOpen, onClose]);

  useEffect(() => {
    if (isOpen && branchesQuery.error) {
      notify(`Error loading branches: ${extractErrorMessage(branchesQuery.error).message}`, 'error');
      console.error(branchesQuery.error);
    }
  }, [branchesQuery.error, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setProjectName('');
      setRepositoryId(null);
      setBranch('');
      setDptProjectPath('');
      setErrorMessage('');
    }
  }, [isOpen]);

  const onCreateClick = useCallback(async () => {
    try {
      if (!projectName) {
        setErrorMessage('Project name is required');
        return;
      }
      if (!repository) {
        setErrorMessage('Repository is required');
        return;
      }
      if (!branch) {
        setErrorMessage('Branch is required');
        return;
      }
      const createProjectParams = {
        folder_id: accountId,
        project: {
          name: projectName,
          repository_provider: repositoryProviders.github.name,
          dbt_project_subdirectory: dbtProjectPath,
          repository_parameters: {
            id: repository.id,
            name: repository.name,
            owner_id: repository.ownerId,
            owner_login: repository.ownerLogin,
            clone_url: repository.cloneUrl,
            branch
          }
        }
      };
      await createProject(createProjectParams).unwrap();
      onClose();
      setProjectName('');
      setRepositoryId(null);
      setBranch('');
      setDptProjectPath('');
      notify('Project created successfully', 'success');
    } catch (e: unknown) {
      notify(`Error creating project: ${extractErrorMessage(e).message}`, 'error');
      console.error(e);
    }
  }, [repository, projectName, branch, dbtProjectPath, createProject, onClose, accountId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Create a new project"
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.primary, text: 'Create project', onClick: onCreateClick, isLoading, dataTestId: 'create-project'}
      ]}>
      <InputField label="Project name">
        <Input
          placeholder="Enter project name"
          value={projectName}
          onInputChange={(e: ChangeEvent<HTMLInputElement>) => setProjectName(e.target.value)}
          onEnter={onCreateClick}
        />
      </InputField>
      <InputField label="Repository">
        <Select
          options={repositories.map((r) => ({ label: r.name, value: r.id }))}
          value={repositoryId}
          onChange={(option) => setRepositoryId(+(option as Option).value)}
          placeholder="Select a repository"
          className="border-grey-300 rounded-md border shadow"
          icon={<repositoryProviders.github.icon className="ml-2 mr-1 text-slate-400" width="16" height="16" fill="#94A3B8" />}
          dataTestId="repository-select"
        />
      </InputField>
      <InputField label="Branch">
        <Select
          options={branchesQuery.data?.map((b) => ({ label: b.name, value: b.name })) || []}
          value={branch}
          onChange={(option) => setBranch((option as Option).value.toString())}
          className="border-grey-300 rounded-md border shadow"
          isLoading={branchesQuery.isLoading}
          isDisabled={!repositoryId}
          placeholder={repositoryId ? 'Select a branch' : 'Select a repository first'}
          icon={<BranchIcon fill="#94A3B8" className="ml-2 mr-1" width="16" height="16" />}
          dataTestId="branch-select"
        />
      </InputField>
      <div
        className="mb-3 mt-5 flex cursor-pointer items-center gap-2 text-text-primary"
        onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
        {showAdvancedOptions ? <ChevronDownIcon width="16" height="16" /> : <ChevronRightIcon width="16" height="16" />}
        Advanced
      </div>
      {showAdvancedOptions && (
        <div className="mb-3">
          <InputField label="Directory of dbt project" labelWidth="w-48" inputWidth="w-52">
            <Input
              placeholder="Enter folder path"
              value={dbtProjectPath}
              onInputChange={(e: ChangeEvent<HTMLInputElement>) => setDptProjectPath(e.target.value)}
            />
          </InputField>
        </div>
      )}
      {errorMessage && <FormError errorMessage={errorMessage} onClose={() => setErrorMessage('')} />}
    </Modal>
  );
};

const InputField = ({
  children,
  label,
  inputWidth = 'w-72',
  labelWidth = 'w-28'
}: {
  children?: React.ReactNode;
  label: string;
  inputWidth?: string;
  labelWidth?: string;
}) => {
  return (
    <div className="mb-2 flex items-center justify-between text-text-primary">
      <div className={labelWidth}>{label}</div>
      <div className={inputWidth}>{children}</div>
    </div>
  );
};

export default CreateProjectModal;
