import { useState } from 'react';
import IBranch, { IBranchSyncConfiguration } from '../../IBranch';
import SyncConfigurationForm from './SyncConfigurationForm';
import { SyncConfigurationSteps } from './Types';
import SyncConfigurationCodePreview from './SyncConfigurationCodePreview';
import { IProjectConfiguration } from '../../../projects/IProject';
import SyncConfigurationApplyChanges from './SyncConfigurationApplyChanges';
import { useAppSelector } from '../../../../infrastructure/state/hooks';
import { selectActiveProject } from '../../../../infrastructure/state/slices/activeProjectSlice';
import { useUpdateProjectConfigurationMutation } from '../../../../services/projects/projects';

type OnClose = () => void;

interface AddSyncConfigurationProps {
  onClose: OnClose;
  branch: IBranch;
  projectConfiguration: IProjectConfiguration;
}

const AddSyncConfiguration = ({ onClose, branch, projectConfiguration }: AddSyncConfigurationProps) => {
  const [newProjectConfiguration, setNewProjectConfiguration] = useState<IProjectConfiguration | null>(null);
  const [step, setStep] = useState(SyncConfigurationSteps.form);
  const projectId = useAppSelector(selectActiveProject);
  const [updateProjectConfiguration] = useUpdateProjectConfigurationMutation();
  const [applyOperationId, setApplyOperationId] = useState<number | null>(null);

  const showPreview = (syncConfiguration: IBranchSyncConfiguration) => {
    setStep(SyncConfigurationSteps.codePreview);
    setNewProjectConfiguration({
      ...projectConfiguration,
      dataModelSync: [...projectConfiguration.dataModelSync, syncConfiguration]
    });
  };

  const apply = async () => {
    setStep(SyncConfigurationSteps.apply);
    const { id: operationId} = await updateProjectConfiguration({ projectId, configuration: newProjectConfiguration! }).unwrap();
    setApplyOperationId(operationId);
  };

  switch (step) {
    case SyncConfigurationSteps.form:
      return <SyncConfigurationForm branch={branch} onClose={onClose} showPreview={showPreview} />;
    case SyncConfigurationSteps.codePreview:
      return (
        <SyncConfigurationCodePreview
          onClose={onClose}
          newProjectConfiguration={newProjectConfiguration}
          apply={apply}
        />
      );
    case SyncConfigurationSteps.apply:
      return <SyncConfigurationApplyChanges operationId={applyOperationId} onClose={onClose} />;
    default:
      return null;
  }
};

export default AddSyncConfiguration;
