import { useSelector } from "react-redux";
import Select, { Option } from "../../../../../components/form/Select";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { useGetAlgoliaCredentialsQuery } from "../../../../../services/accounts";
import { useEffect, useState } from "react";
import { getNodes } from "../../../../../services/nodes/nodes";
import { AlgoliaCredentials } from "../../../../../services/types";
import { SubnodeType } from "../../../../models/discover/INode";
import { NewAggregateTableChangeColumn, NewAggregateTableChangeColumnType } from "../types";
import { generateAggregateTableColumn } from "../utilities/generateAggregateTableColumn";

type AggregateTableMetricsFormProps = {
    columns: NewAggregateTableChangeColumn[];
    setColumns: (columns: NewAggregateTableChangeColumn[]) => void;
    dbtProjectName: string;
}

type SelectableMetric = {
    metricName: string;
    modelName: string;
}

export const AggregateTableMetricsForm = ({ columns, setColumns, dbtProjectName }: AggregateTableMetricsFormProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const getAlgoliaCredentialsQuery = useGetAlgoliaCredentialsQuery({ accountId });
    const [metrics, setMetrics] = useState<SelectableMetric[]>([]);
    useEffect(() => {
        getMetrics(getAlgoliaCredentialsQuery.data || null, dbtProjectName).then(metrics => setMetrics(metrics));
    }, [getAlgoliaCredentialsQuery.data, dbtProjectName]);
    const onChange = (options: Option | Option[]) => {
        const columnsWithoutMetrics = columns.filter(c => c.type !== NewAggregateTableChangeColumnType.metric);
        const metricColumns = (options as Option[]).map(o => {
            const metric = metrics.find(m => m.metricName === o.value);
            if (!metric) {
                throw new Error('Metric not found in selected metrics list.');
            }
            return generateAggregateTableColumn({ name: metric.metricName, type: NewAggregateTableChangeColumnType.metric, dataType: 'float' });
        });
        setColumns([...columnsWithoutMetrics, ...metricColumns]);
    };
    return (
        <Select
            placeholder="Select metrics"
            className="w-full rounded border shadow"
            isMulti={true}
            options={metrics.map(m => ({ label: m.metricName, value: m.metricName }))}
            value={columns.map(c => c.name)}
            onChange={onChange}
            height="fit-content"
        />
    );
};

const getMetrics = async (credentials: AlgoliaCredentials | null, dbtProjectName: string): Promise<SelectableMetric[]> => {
    if (!credentials) return [];
    const { items } = await getNodes({
        apiKey: credentials.key,
        applicationId: credentials.app_id,
        indexName: credentials.index_name,
        pageSize: 1000,
        filters: `type:dbt_model AND number_of_metrics>0 AND dbt_project_name:${dbtProjectName}`
    });
    return items.flatMap(n => n.subnodes.filter(sn => sn.type === SubnodeType.Metric).map(m => ({ metricName: m.name, modelName: n.name})));
};