import { NodeSidepane } from "../NodeSidepane";
import { IExpandedNode } from "../../../INode";
import { buttonsWithWorkbook } from "../buttonsWithWorkbook";

type TableauDashboardSidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const TableauDashboardSidepane = ({ node, setCanCloseModal }: TableauDashboardSidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        ButtonsComponent={buttonsWithWorkbook(node)}
    />;
};