import { useEffect, useState } from 'react';
import Layout from '../../../components/layout/Layout';
import { ChangeStatus, IChangePage } from '../IChange';
import { ApplicationOptions, IFilter, SortOptions } from './Types';
import { ClockIcon } from '@heroicons/react/24/solid';
import EvolutionFiltersBar from './filters/EvolutionFiltersBar';
import { useNavigate } from 'react-router-dom';
import { useGetAccountProjectsQuery } from '../../../services/projects/projects';
import PageLoader from '../../../components/loaders/PageLoader';
import { ModelChangesList } from './ModelChangesList';
import EditChangeSidepane from '../editChangeSidepane/EditChangeSidepane';
import { useGetChangesQuery, useLazyGetChangeQuery } from '../../../services/changes/changes';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import TopBar from '../../../components/layout/TopBar';
import { PageTabs } from '../../../components/PageTabs';
import { selectActiveProject } from '../../../infrastructure/state/slices/activeProjectSlice';
import { ChangeAction } from '../editChangeSidepane/Types';

const baseFilters = {
  owner: '',
  createdAt: null,
  application: '' as ApplicationOptions
};

enum Tab {
  Proposals = 'Proposals',
  InProgress = 'In progress',
  Archived = 'Archived'
}

const tabToStatuses = {
  [Tab.Proposals]: [ChangeStatus.Proposal],
  [Tab.InProgress]: [ChangeStatus.Deployed, ChangeStatus.Merged, ChangeStatus.Open, ChangeStatus['Pending Merge']],
  [Tab.Archived]: [ChangeStatus.Published, ChangeStatus['PR Rejected'], ChangeStatus['Draft Rejected']]
};

export const ModelChanges = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sortBy, setSortBy] = useState<SortOptions>('newest');
  const [searchInput, setSearchInput] = useState('');
  const [filters, setFilters] = useState<IFilter>(baseFilters);
  const [projectId, setProjectId] = useState<number | null>(null);
  const [showCreateProposalSidepane, setShowCreateProposalSidepane] = useState(false);
  const accountId = useSelector(selectActiveAccountId);
  const [tab, setTab] = useState<Tab>(Tab.Proposals);
  const navigate = useNavigate();
  const activeProjectId = useSelector(selectActiveProject);
  const getProjectsQuery = useGetAccountProjectsQuery({ accountId });

  useEffect(() => {
    if (getProjectsQuery.data && !projectId) {
      setProjectId(getProjectsQuery.data[0].id);
    }
  }, [getProjectsQuery.data, projectId]);

  useEffect(() => {
    if (activeProjectId && !projectId) {
      setProjectId(activeProjectId);
    }
  }, [activeProjectId, projectId]);

  const getChangesQuery = useGetChangesQuery({
    search: searchInput,
    owner: filters.owner,
    application: filters.application,
    createdAt: filters.createdAt,
    sortBy,
    statuses: tabToStatuses[tab],
    page,
    pageSize,
    projectId: projectId || 0
  }, { skip: !projectId });
  const [getChangeQuery, selectedChange] = useLazyGetChangeQuery();
  const { items: changes = [], total = 0 } = getChangesQuery.data || {};

  const onClick = async (change: IChangePage) => {
    if (tab === Tab.Proposals) {
      setShowCreateProposalSidepane(false);
      await getChangeQuery({ changeId: change.id, projectId: projectId || 0 });
      setShowCreateProposalSidepane(true);
    }
    else {
      navigate(`/project/${projectId}/evolution/change/${change.id}`);
    }
  };

  const selectTab = (tab: Tab) => {
    setTab(tab);
    setPage(1);
  };

  if (getProjectsQuery.isLoading || getChangesQuery.isLoading) {
    return <PageLoader label="Loading" />;
  } else {
    return (
      <Layout>
        <TopBar>
          <PageTabs
            tabs={[
              { name: 'Proposals', isActive: tab === Tab.Proposals, onClick: () => selectTab(Tab.Proposals) },
              { name: 'In progress', isActive: tab === Tab.InProgress, onClick: () => selectTab(Tab.InProgress) },
              { name: 'Archived', isActive: tab === Tab.Archived, onClick: () => selectTab(Tab.Archived) }
            ]}
          />
        </TopBar>
        <div className="py-8 px-14">
          <EvolutionFiltersBar
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            sortBy={sortBy}
            setSortBy={setSortBy}
            filters={filters}
            setFilters={setFilters}
            projects={getProjectsQuery.data || []}
            selectedProjectId={projectId}
            setSelectedProjectId={setProjectId}
          />
          {(changes.length === 0 && !filters.createdAt && !filters.owner) ? (
            <NoData />
          ) : (
            <ModelChangesList changes={changes} onClick={onClick} pagination={{ page, pageSize, total, setPage, setPageSize }} />
          )}

          {!selectedChange.isLoading && selectedChange.data && (
            <EditChangeSidepane
              isOpen={showCreateProposalSidepane}
              onClose={() => setShowCreateProposalSidepane(false)}
              action={ChangeAction.Propose}
              change={selectedChange.data}
            />
          )}
        </div>
      </Layout>
    );
  }
};

const NoData = () => {
  return (
    <div className="mt-40 flex flex-col items-center">
      <div className="w-fit rounded-full bg-slate-200 p-3">
        <ClockIcon width="48" height="48" className="text-white" />
      </div>
      <div className="mt-4 text-slate-300">No model changes yet for this project</div>
    </div>
  );
};
