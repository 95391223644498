import { useNavigate } from "react-router-dom";
import { SparklesLeftArrowIcon } from "../../../assets/images/icons/DelphiIcons";
import { useDispatch } from "react-redux";
import activeProjectSlice from "../../../infrastructure/state/slices/activeProjectSlice";

export const ProjectProposals = ({ total, projectId }: { total: number, projectId: number }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    if (!total) return null;
    const viewProposals = () => {
        dispatch(activeProjectSlice.actions.setActiveProject(projectId));
        navigate(`/model-changes`);
    };
    return (
        <div className="text-sm mt-4 flex items-center p-2 bg-muse-50 gap-1 rounded-md">
            <div><SparklesLeftArrowIcon width="14" height="14" fill="#0047FF" /></div>
            <div className="text-primary">{ total } proposals</div>
            <div className="ml-auto text-surface-primary font-medium cursor-pointer" onClick={viewProposals}>View</div>
        </div>
    );
};