import { Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { store } from '../infrastructure/state/store';
import { accountsApi } from '../services/accounts';
import { notify } from '../components/Toaster';
import type {} from 'redux-thunk/extend-redux';
import Project from '../features/projects/Project';
import Account from '../features/account/Account';
import activeAccountSlice from '../infrastructure/state/slices/activeAccountSlice';
import ManageProject from '../features/projects/manage/ManageProject';
import Discover from '../features/models/discover/Discover';
import Change from '../features/evolution/changePage/Change';
import { NodeLookup } from '../features/models/discover/NodeLookup';
import { ProjectsPage } from '../features/projects/ProjectsPage/ProjectsPage';
import { InternalTools } from '../features/internalTools/InternalTools';
import { ModelChanges } from '../features/evolution/changesPage/ModelChanges';

const privateRoutes = () => {
  return [
    {
      element: <PrivateRoute />,
      loader: async () => {
        const { isSuccess, data } = await store.dispatch(accountsApi.endpoints.getAccounts.initiate());
        const activeAccount = store.getState().activeAccount;
        if (!isSuccess) {
          notify('Failed to load accounts', 'error');
        } else if (data.length === 0) {
          notify('No accounts found', 'error');
        } else if (!activeAccount) {
          await store.dispatch(activeAccountSlice.actions.setActiveAccount(data[0].id));
        }
        return null;
      }, // load accounts data at the top level
      children: [
        { path: '/', element: <ProjectsPage /> },
        { path: '/project/:projectId/team', element: <div className="p-4">Team</div> },
        { path: '/data-model', element: <Discover /> },
        { path: '/project/:projectId/manage', element: <ManageProject /> },
        { path: '/project/:projectId', element: <Project /> },
        {path: '/model-changes', element: <ModelChanges />},
        {path: '/project/:projectId/evolution/change/:changeId', element: <Change />},
        { path: '/account', element: <Account /> },
        { path: '/node-lookup', element: <NodeLookup />},
        { path: '/projects', element: <ProjectsPage />},
        { path: '/internal-tools', element: <InternalTools />},
        { path: '*', element: <Navigate to="/" /> }
      ]
    }
  ];
};

export default privateRoutes;
