import { ChevronDownIcon, ChevronUpIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { notify } from "../Toaster";
import { useEffect, useState } from "react";
import { CodeViewerProps } from "./types";

export const CodeViewer = ({ code, fileName, collapsable = false, className = '' }: CodeViewerProps) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    useEffect(() => {
        setIsCollapsed(false);
    }, [code, setIsCollapsed]);
    return (
        <div>
            {
                fileName && collapsable && (
                    <div onClick={() => setIsCollapsed(!isCollapsed)} className="items-center cursor-pointer flex gap-2 rounded-t-lg bg-slate-200 p-2 text-sm text-text-primary">
                        <div>
                            {
                                isCollapsed ? <ChevronDownIcon width="14" height="14" /> : <ChevronUpIcon width="14" height="14" />
                            }
                        </div>
                        {
                            fileName
                        }
                    </div>
                )
            }
            {
                !isCollapsed && (
                    <div className={`${collapsable ? 'rounded-b-lg' : 'rounded-lg'} flex flex-col h-auto max-h-64 border border-slate-200 bg-slate-50 gap-1 p-2 ${className}`}>
                        <div className="ml-auto p-1 -mr-1 -mt-1 rounded border border-slate-200 bg-white hover:bg-slate-50 cursor-pointer text-slate-400 w-fit h-fit" title="copy" onClick={() => copyCode(code)}>
                            <DocumentDuplicateIcon width="14" height="14" />
                        </div>
                        <div className="overflow-auto flex-1 whitespace-pre text-sm text-text-primary">
                            {code}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const copyCode = (code: string) => {
    code && navigator.clipboard.writeText(code);
    notify('Copied to clipboard', 'success');
};