import algoliasearch, { SearchClient } from 'algoliasearch';
import { IExpandedNode, ISuperficialNode, MetricDependency } from '../../features/models/discover/INode';
import { transformAlgoliaNodeToLocalNode, transformBackendExpandedNodeToLocalExpandedNode, transformBackendSuperficialNodeToLocalSuperficialNode, transformMetricDependenciesResponseToLocal } from './transformers';
import api from '../api';
import { AlgoliaNode, BackendExpandedNodeResponse, BackendSuperficialNode } from './types';

let algoliaClient: SearchClient | null = null;

type AlgoliaCredentials = {
  applicationId: string;
  apiKey: string;
}

type GetNodesProps = AlgoliaCredentials & {
  indexName: string;
  pageSize?: number;
  page?: number;
  query?: string;
  filters?: string;
}

type GetNodesReponse = Promise<{ items: ISuperficialNode[], total: number }>;

export const nodesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExpandedNode: build.query<IExpandedNode | null, { accountId: number, nodeId: string }>({
      query: ({ accountId, nodeId }) => `accounts/${accountId}/data_transformations/lookup?utl=${encodeURIComponent(nodeId)}`,
      transformResponse: (response: BackendExpandedNodeResponse) => {
        return transformBackendExpandedNodeToLocalExpandedNode(response);
      }
    }),
    getConnectedSuperficialNodes: build.query<ISuperficialNode[], { limit: number, accountId: number, nodeIds: string[], direction?: 'downstream' | 'upstream' | 'both', depth?: number | null }>({
      query: ({ accountId, nodeIds, limit, direction = 'both', depth = null }) => ({
        url: `accounts/${accountId}/data_transformations/paths`,
        method: 'POST',
        body: { utls: nodeIds, max_nodes_threshold: limit, direction, depth },
      }),
      transformResponse: (response: BackendSuperficialNode[]) => {
        return response.map(transformBackendSuperficialNodeToLocalSuperficialNode);
      }
    }),
    getMetricsDependencies: build.query<MetricDependency[], { projectId: number, metrics: string[] }>({
      query: ({ projectId, metrics }) => `projects/${projectId}/metrics_projectables?metrics=${metrics.join(',')}`,
      transformResponse: transformMetricDependenciesResponseToLocal
    })
  })
});

export const { useGetExpandedNodeQuery, useGetConnectedSuperficialNodesQuery, useGetMetricsDependenciesQuery } = nodesApi;

export const getNodes = async ({ query = '', applicationId, apiKey, indexName, page = 0, pageSize = 50, filters }: GetNodesProps): GetNodesReponse => {
  const algoliaClient = getAlgoliaClient({ applicationId, apiKey });
  const index = algoliaClient.initIndex(indexName);
  const results = await index.search(query, { hitsPerPage: pageSize, page, filters, cacheable: false });
  const superficialNodes = results.hits.map(hit => transformAlgoliaNodeToLocalNode(hit as AlgoliaNode));
  return {
    items: superficialNodes,
    total: results.nbHits
  };
};

export const getFilterOptions = async ({ applicationId, apiKey, indexName }: AlgoliaCredentials & { indexName: string }) => {
  const parameters = ['tags', 'materialized', 'meta', 'database', 'database_schema', 'dbt_project_name', 'type', 'looker_project', 'looker_folder', 'looker_model', 'source_directory', 'looker_host', 'tableau_project', 'tableau_workbook', 'owner'];
  const algoliaClient = getAlgoliaClient({ applicationId, apiKey });
  const index = algoliaClient.initIndex(indexName);
  const { facets } = await index.search('', { facets: parameters });
  const sortedFacets: { [key: string]: string[] } = {};
  for (const [key, value] of Object.entries(facets as { [key: string]: { [key: string]: number } })) {
    sortedFacets[key] = Object.keys(value).sort((a, b) => value[b] - value[a]);
  }
  return sortedFacets;
};

type SearchFilterValuesProps = AlgoliaCredentials & {
  indexName: string;
  filterName: string;
  filterQuery: string;
  searchParameters?: { filters: string };
}

export const searchFilterValues = async ({ applicationId, apiKey, indexName, filterName, filterQuery, searchParameters }: SearchFilterValuesProps) => {
  const algoliaClient = getAlgoliaClient({ applicationId, apiKey });
  const index = algoliaClient.initIndex(indexName);
  const { facetHits } = await index.searchForFacetValues(filterName, filterQuery, searchParameters);
  return facetHits.map(hit => hit.value);
};

const getAlgoliaClient = ({ applicationId, apiKey }: AlgoliaCredentials) => {
  if (!algoliaClient) {
    algoliaClient = algoliasearch(applicationId, apiKey);
  }
  return algoliaClient;
};
