
import { IChange, NewModelChangeData } from '../../IChange';
import { ImplicationNode } from '../../formSections/ImplicationNode';
import { NodeType } from '../../../models/discover/INode';
import { SparklesLeftArrowIcon } from '../../../../assets/images/icons/DelphiIcons';

export const NewModelImplications = ({ proposal }: { proposal: IChange }) => {
  const changeData = proposal.changeData as NewModelChangeData;
  const nodeWidth = 350;
  return (
    <div className="flex justify-between gap-2 flex-1">
      <ImplicationNode nodeWidth={nodeWidth} nodeName={changeData.newModelName} nodeType={NodeType.DataModel} isNew={true} />
      <div className="self-center text-tertiary">
        <SparklesLeftArrowIcon width="22" height="22" fill="#0047FF" />
      </div>
      <ImplicationNode utl={proposal.sourceUtl} nodeWidth={nodeWidth} nodeName={proposal.sourceName} nodeType={NodeType.LookerLook} isNew={false} />
    </div>
  );
};