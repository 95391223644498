import {
    IExpandedNode,
    IMeta,
    INodeUsage,
    ISuperficialNode,
    MetricDependency,
    NodeType,
    SubnodeType,
    Swimlane
} from '../../features/models/discover/INode';
import {
    AlgoliaNode,
    BackendSubnodeType,
    BackendExpandedNodeResponse,
    BackendSuperficialNode,
    BackendNodeType,
    ExternalNodeUsage,
    MetricDependenciesResponse,
    BackendExpandedSubnode
} from './types';

export const transformAlgoliaNodeToLocalNode = (algoliaNode: AlgoliaNode): ISuperficialNode => {
    const nodeType = mapBackendNodeTypeToLocalNodeType.get(algoliaNode.type);
    if (!nodeType) {
        throw new Error(`Unknown Algolia node type: ${algoliaNode.type}`);
    }
    const node: ISuperficialNode = {
        id: algoliaNode.objectID,
        parents: algoliaNode.parents,
        type: nodeType,
        generatedByDelphi: algoliaNode.generated_by_delphi,
        name: algoliaNode.name,
        description: algoliaNode.description,
        tags: algoliaNode.tags,
        meta: transformAlgoliaMetaToMeta(algoliaNode.meta || []),
        materialization: algoliaNode.materialized,
        database: algoliaNode.database,
        databaseSchema: algoliaNode.schema,
        repo: algoliaNode.git_repo_url,
        branch: algoliaNode.git_repo_branch,
        package: algoliaNode.package_name,
        identifier: algoliaNode.unique_id,
        parentName: algoliaNode.dbt_project_name,
        numberOfDimensions: algoliaNode.number_of_dimensions,
        numberOfMeasures: algoliaNode.number_of_measures,
        numberOfEntities: algoliaNode.number_of_entities,
        numberOfMetrics: algoliaNode.number_of_metrics,
        numberOfCustomFields: algoliaNode.number_of_custom_fields,
        swimlane: getSwimlaneForNodeType(nodeType),
        numberOfColumns: algoliaNode.number_of_columns || 0,
        proposals: algoliaNode.proposals || false,
        owner: algoliaNode.owner,
        subnodes: (algoliaNode.subnodes || []).map((subnode) => ({
            name: subnode.name,
            description: subnode.description,
            type: mapBackendSubnodeTypeToLocalSubnodeType.get(subnode.type) || SubnodeType.Column
        })),
        //Forward compatibility with the new node structure in the backend. Remove when the backend is updated.
        hasDownstreamNodes: typeof algoliaNode.has_downstream_nodes === 'boolean' ? algoliaNode.has_downstream_nodes : true,
        hasUpstreamNodes: typeof algoliaNode.has_upstream_nodes === 'boolean' ? algoliaNode.has_upstream_nodes : true
    };
    return node;
};

export const mapBackendNodeTypeToLocalNodeType = new Map<BackendNodeType, NodeType>([
    [BackendNodeType.DataModel, NodeType.DataModel],
    [BackendNodeType.DataSource, NodeType.DataSource],
    [BackendNodeType.LookerView, NodeType.LookerView],
    [BackendNodeType.LookerDerivedView, NodeType.LookerDerivedView],
    [BackendNodeType.LookerExplore, NodeType.LookerExplore],
    [BackendNodeType.LookerLook, NodeType.LookerLook],
    [BackendNodeType.Orphan, NodeType.Orphan],
    [BackendNodeType.LookerDashboard, NodeType.LookerDashboard],
    [BackendNodeType.LookerTile, NodeType.LookerTile],
    [BackendNodeType.TableauWorkbook, NodeType.TableauWorkbook],
    [BackendNodeType.TableauView, NodeType.TableauView],
    [BackendNodeType.TableauCustomQuery, NodeType.TableauCustomQuery],
    [BackendNodeType.TableauPublishedDataSource, NodeType.TableauPublishedDataSource],
    [BackendNodeType.TableauEmbeddedDataSource, NodeType.TableauEmbeddedDataSource],
    [BackendNodeType.TableauDashboard, NodeType.TableauDashboard],
    [BackendNodeType.TableauStory, NodeType.TableauStory]
]);

export const mapBackendSubnodeTypeToLocalSubnodeType = new Map<BackendSubnodeType, SubnodeType>([
    [BackendSubnodeType.Dimension, SubnodeType.Dimension],
    [BackendSubnodeType.Measure, SubnodeType.Measure],
    [BackendSubnodeType.Entity, SubnodeType.Entity],
    [BackendSubnodeType.Metric, SubnodeType.Metric],
    [BackendSubnodeType.CustomField, SubnodeType.CustomField],
    [BackendSubnodeType.Column, SubnodeType.Column],
    [BackendSubnodeType.TableCalculation, SubnodeType.TableCalculation],
    [BackendSubnodeType.LookerConnectedView, SubnodeType.LookerConnectedView],
    [BackendSubnodeType.LookerLook, SubnodeType.LookerLook],
    [BackendSubnodeType.LookerTile, SubnodeType.LookerTile],
    [BackendSubnodeType.TableauConnectedView, SubnodeType.TableauConnectedView]
]);

export const mapLocalSubnodeTypeToBackendSubnodeType = new Map<SubnodeType, BackendSubnodeType>([
    [SubnodeType.Dimension, BackendSubnodeType.Dimension],
    [SubnodeType.Measure, BackendSubnodeType.Measure],
    [SubnodeType.Entity, BackendSubnodeType.Entity],
    [SubnodeType.Metric, BackendSubnodeType.Metric],
    [SubnodeType.CustomField, BackendSubnodeType.CustomField],
    [SubnodeType.Column, BackendSubnodeType.Column],
    [SubnodeType.TableCalculation, BackendSubnodeType.TableCalculation],
    [SubnodeType.LookerConnectedView, BackendSubnodeType.LookerConnectedView],
    [SubnodeType.LookerLook, BackendSubnodeType.LookerLook],
    [SubnodeType.LookerTile, BackendSubnodeType.LookerTile]
]);

export const mapNodeTypeToBackendNodeType = new Map<NodeType, BackendNodeType>([
    [NodeType.DataModel, BackendNodeType.DataModel],
    [NodeType.DataSource, BackendNodeType.DataSource],
    [NodeType.LookerView, BackendNodeType.LookerView],
    [NodeType.LookerDerivedView, BackendNodeType.LookerDerivedView],
    [NodeType.LookerExplore, BackendNodeType.LookerExplore],
    [NodeType.LookerLook, BackendNodeType.LookerLook],
    [NodeType.Orphan, BackendNodeType.Orphan],
    [NodeType.LookerDashboard, BackendNodeType.LookerDashboard],
    [NodeType.LookerTile, BackendNodeType.LookerTile],
    [NodeType.TableauWorkbook, BackendNodeType.TableauWorkbook],
    [NodeType.TableauView, BackendNodeType.TableauView]
]);

export const mapAlgoliaSubnodeTypeToLocalSubnodeType = new Map<BackendSubnodeType, SubnodeType>([
    [BackendSubnodeType.Dimension, SubnodeType.Dimension],
    [BackendSubnodeType.Measure, SubnodeType.Measure],
    [BackendSubnodeType.Entity, SubnodeType.Entity],
    [BackendSubnodeType.Metric, SubnodeType.Metric],
    [BackendSubnodeType.CustomField, SubnodeType.CustomField],
    [BackendSubnodeType.Column, SubnodeType.Column],
    [BackendSubnodeType.TableCalculation, SubnodeType.TableCalculation],
    [BackendSubnodeType.LookerConnectedView, SubnodeType.LookerConnectedView],
    [BackendSubnodeType.LookerLook, SubnodeType.LookerLook],
    [BackendSubnodeType.LookerTile, SubnodeType.LookerTile]
]);

export const mapLocalSubnodeTypeToAlgoliaSubnodeType = new Map<SubnodeType, BackendSubnodeType>([
    [SubnodeType.Dimension, BackendSubnodeType.Dimension],
    [SubnodeType.Measure, BackendSubnodeType.Measure],
    [SubnodeType.Entity, BackendSubnodeType.Entity],
    [SubnodeType.Metric, BackendSubnodeType.Metric],
    [SubnodeType.CustomField, BackendSubnodeType.CustomField],
    [SubnodeType.Column, BackendSubnodeType.Column],
    [SubnodeType.TableCalculation, BackendSubnodeType.TableCalculation],
    [SubnodeType.LookerConnectedView, BackendSubnodeType.LookerConnectedView],
    [SubnodeType.LookerLook, BackendSubnodeType.LookerLook],
    [SubnodeType.LookerTile, BackendSubnodeType.LookerTile]
]);

const getSwimlaneForNodeType = (nodeType: NodeType): Swimlane => {
    const mapNodeTypeToSwimlane = new Map<NodeType, Swimlane>([
        [NodeType.DataModel, Swimlane.transformation],
        [NodeType.DataSource, Swimlane.sources],
        [NodeType.LookerView, Swimlane.appModeling],
        [NodeType.LookerDerivedView, Swimlane.appModeling],
        [NodeType.LookerExplore, Swimlane.appModeling],
        [NodeType.LookerLook, Swimlane.application],
        [NodeType.LookerTile, Swimlane.application],
        [NodeType.LookerDashboard, Swimlane.application],
        [NodeType.Orphan, Swimlane.transformation],
        [NodeType.TableauWorkbook, Swimlane.application],
        [NodeType.TableauView, Swimlane.application],
        [NodeType.TableauCustomQuery, Swimlane.appModeling],
        [NodeType.TableauPublishedDataSource, Swimlane.appModeling],
        [NodeType.TableauEmbeddedDataSource, Swimlane.appModeling],
        [NodeType.TableauDashboard, Swimlane.application],
        [NodeType.TableauStory, Swimlane.application]
    ]);
    const swimlane = mapNodeTypeToSwimlane.get(nodeType);
    if (!swimlane) {
        throw new Error(`Unknown node type: ${nodeType}`);
    }
    return swimlane;
};

const transformAlgoliaMetaToMeta = (algoliaMeta: string[]): IMeta => {
    if (typeof algoliaMeta === 'object' && !Array.isArray(algoliaMeta)) {
        return algoliaMeta;
    }
    const meta: IMeta = {};
    for (const metaString of algoliaMeta) {
        const [key, value] = metaString.split('=');
        meta[key] = value;
    }
    return meta;
};

export const transformBackendExpandedNodeToLocalExpandedNode = (node: BackendExpandedNodeResponse): IExpandedNode => {
    const nodeType = mapBackendNodeTypeToLocalNodeType.get(node.type);
    const rawCode = node.table_properties?.view_native_code?.code || node.raw_code || '';
    if (!nodeType) {
        throw new Error(`Unknown Backend node type: ${node.type}`);
    }
    return {
        subnodes: [...buildSubondes(node), ...node.subnodes].map((subnode) => ({
            name: subnode.name,
            description: subnode.description,
            tags: subnode.tags || [],
            meta: subnode.meta || {},
            parentName: subnode.parent_name,
            type: mapBackendSubnodeTypeToLocalSubnodeType.get(subnode.subnode_type) || SubnodeType.Column,
            proposalId: subnode.proposal_ids ? subnode.proposal_ids[0] : null,
            withCode: subnode.source_code || subnode.compiled_code ? true : false,
            subType: subnode.type,
            rawCode: subnode.source_code || '',
            compiledCode: subnode.compiled_code || '',
            semanticCode: '',
            url: null,
            id: subnode.utl || '',
            usage: subnode.looker_queries ? transformUsageFromExternalToInternal(subnode.looker_queries) : null,
            last30DaysViews: subnode.last_30d_views || null,
            last7DaysViews: subnode.last_7d_views || null,
            alias: subnode.alias || null,
            typeSpecificInfo: buildSubnodeTypeSpecificInfo(subnode)
        })),
        lastAccessedAt: node.last_accessed_at,
        favouriteCount: node.favourite_count,
        url: node.url,
        lastUpdatedAt: node.updated_at,
        lastUpdatedBy: node.native_updated_by?.email || node.updated_by || null,
        createdAt: node.created_at,
        createdBy: node.native_created_by?.email || node.created_by || null,
        id: node.utl,
        name: node.name,
        type: nodeType,
        description: node.description,
        tags: node.tags || [],
        meta: node.meta || {},
        generatedByDelphi: node.generated_by_delphi,
        materialization: node.materialized,
        last30DaysViews: node.last_30d_views || null,
        last7DaysViews: node.last_7d_views || null,
        package: node.package_name,
        database: node.database,
        databaseSchema: node.database_schema,
        identifier: '',
        parentName: node.parent_name,
        repo: node.git_repo_url,
        branch: node.git_repo_branch,
        rawCode,
        compiledCode: node.compiled_code,
        semanticCode: node.semantic_code,
        userAllowedToPromote: node.allow_promote,
        withCode: node.compiled_code || rawCode || node.semantic_code ? true : false,
        sourceDirectory: node.source_directory || '',
        lookerFolder: node.looker_folder || '',
        lookerModel: node.looker_model || '',
        lookerProject: node.looker_project || '',
        proposalId: node.proposal_ids?.[0] || null,
        usage: node.looker_queries ? transformUsageFromExternalToInternal(node.looker_queries) : null,
        dbtProjectName: node.dbt_project,
        eunoProjectId: node.euno_project_id || null,
        typeSpecificInfo: buildNodeTypeSpecificInfo(node),
        containedNodes:
            node.contained_resources?.map((resource) => ({
                name: resource.name,
                id: resource.uri,
                type: mapBackendNodeTypeToLocalNodeType.get(resource.normalized_type) || NodeType.Orphan
            })) || [],
        chainedNodes:
            node.container_chain?.map((resource) => ({
                name: resource.name,
                id: resource.uri,
                type: mapBackendNodeTypeToLocalNodeType.get(resource.normalized_type) || NodeType.Orphan
            })) || []
    };
};

const buildSubondes = (node: BackendExpandedNodeResponse): BackendExpandedSubnode[] => {
    const emptySubnode = {
        proposal_ids: [],
        compiled_code: null,
        utl: null,
        looker_queries: null,
        last_30d_views: null,
        last_7d_views: null,
        filter: null,
        alias: null
    };
    const columns =
        node.table_schema?.columns?.map((column) => ({
            name: column.name,
            description: column.description,
            type: column.normalized_data_type,
            subnode_type: column.semantic_role,
            tags: column.native_tags,
            meta: column.native_meta,
            parent_name: node.name,
            source_code: column.native_code?.code || null,
            ...emptySubnode
        })) || [];
    return [...columns];
};

const buildSubnodeTypeSpecificInfo = (subnode: BackendExpandedSubnode) => {
    const typeSpecificInfo: { [key: string]: string } = {};
    if (subnode.alias) {
        typeSpecificInfo.alias = subnode.alias;
    }
    if (subnode.type) {
        typeSpecificInfo.type = subnode.type;
    }
    return typeSpecificInfo;
};

const buildNodeTypeSpecificInfo = (node: BackendExpandedNodeResponse) => {
    const typeSpecificInfo: { [key: string]: string } = {};
    if (node.owner?.email) {
        typeSpecificInfo['Owner'] = node.owner.email;
    }
    if (node.tableau_workbook && node.type !== BackendNodeType.TableauWorkbook) {
        typeSpecificInfo['Workbook'] = node.tableau_workbook;
    }
    if (Array.isArray(node.container_chain) && node.container_chain.length > 0) {
        typeSpecificInfo['Browse path'] = node.container_chain.map(({ name }) => name).join('/');
    }
    return typeSpecificInfo;
};

export const transformUsageFromExternalToInternal = (usage: ExternalNodeUsage): INodeUsage => {
    return {
        usage14Days: usage.total_queries_14d,
        sources: usage.breakdown_by_app.map((app) => ({
            title: app.app.title || '',
            number: app.cnt || 0,
            utl: app.app.utl,
            dashboardTitle: app.app.dashboard_element_title,
            type: app.app.type
        })),
        users: usage.breakdown_by_user.map((user) => ({
            name: user.user.user_name,
            number: user.cnt,
            email: user.user.user_email
        }))
    };
};

export const transformBackendSuperficialNodeToLocalSuperficialNode = (
    node: BackendSuperficialNode
): ISuperficialNode => {
    const nodeType = mapBackendNodeTypeToLocalNodeType.get(node.type);
    if (!nodeType) {
        throw new Error(`Unknown Algolia node type: ${node.type}`);
    }
    return {
        id: node.utl,
        name: node.name,
        swimlane: getSwimlaneForNodeType(nodeType),
        parents: node.parents,
        type: nodeType,
        description: '',
        tags: [],
        meta: {},
        generatedByDelphi: node.generated_by_delphi,
        materialization: '',
        database: '',
        databaseSchema: '',
        repo: '',
        branch: '',
        package: '',
        identifier: '',
        parentName: '',
        numberOfDimensions: 0,
        numberOfMeasures: 0,
        numberOfEntities: 0,
        numberOfMetrics: 0,
        numberOfCustomFields: 0,
        proposals: false,
        subnodes: [],
        owner: null,
        //Forward compatibility with the new node structure in the backend. Remove when the backend is updated.
        hasDownstreamNodes: typeof node.has_downstream_nodes === 'boolean' ? node.has_downstream_nodes : true,
        hasUpstreamNodes: typeof node.has_upstream_nodes === 'boolean' ? node.has_upstream_nodes : true
    };
};

export const transformMetricDependenciesResponseToLocal = (
    response: MetricDependenciesResponse
): MetricDependency[] => {
    const metricDependencies: MetricDependency[] = [];
    for (const entity of response) {
        const existingEntity = metricDependencies.find((e) => e.entityName === entity.entity_name);
        const dependencies = entity.dimensions.map((d) => ({ name: d.name, type: d.type, description: '' }));
        if (existingEntity) {
            if (entity.entity_path) {
                existingEntity.entityPaths.push(entity.entity_path);
            }
            existingEntity.dimensions.push(
                ...dependencies.filter((d) => !existingEntity.dimensions.find((e) => e.name === d.name))
            );
        } else {
            metricDependencies.push({
                entityName: entity.entity_name,
                entityPaths: entity.entity_path ? [entity.entity_path] : [],
                dimensions: dependencies,
                entityDescription: ''
            });
        }
    }
    return metricDependencies;
};
