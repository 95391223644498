import { FilterPanelProps } from '../Types';
import { DateFilterValue, dateFilterOptions } from './dateFilter';

const DateFilterPanel = ({ filters, setFilters }: FilterPanelProps) => {
  const selectDate = (value: string | null) => {
    setFilters({ ...filters, createdAt: value as DateFilterValue });
  };
  return (
    <div className="w-40">
      {dateFilterOptions.map((option) => (
        <div
          key={option.label}
          onClick={() => selectDate(option.value)}
          className="cursor-pointer rounded-md bg-white p-3 text-left text-slate-600 hover:bg-slate-50 text-sm">
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default DateFilterPanel;
