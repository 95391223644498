import { useState } from "react";

interface Props {
    text: string;
    maxChars: number;
}

export const ExpandableText = ({ text, maxChars }: Props) => {
    const [showMore, setShowMore] = useState(false);
    const exceededMaxChar = text?.length > maxChars;
    const textWithEllipsis = exceededMaxChar && !showMore ? `${text.substring(0, maxChars)}...` : text;
    return (
        <>
            { textWithEllipsis }
            {
                exceededMaxChar && (
                    <span className="cursor-pointer text-tertiary font-medium" onClick={() => setShowMore(!showMore)}> {showMore ? 'Show less' : 'Show more'}</span>
                )
            }
        </>
    );
};
