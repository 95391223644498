import { useEffect, useRef, useState } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import FilterPanel from './DiscoveryFilterPanel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { discoverFilterList } from './DiscoverFilterList';
import { Filter, FilterOptions } from './Types';
import { useGetAlgoliaCredentialsQuery } from '../../../../../services/accounts';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../../../infrastructure/state/slices/activeAccountSlice';
import { getFilterOptions } from '../../../../../services/nodes/nodes';
import { notify } from '../../../../../components/Toaster';
import { DiscoverFiltersMenu } from './DiscoverFiltersMenu';
import { useKeyPress } from 'react-use';
import { isTypingInInput } from '../../../../../infrastructure/domUtilies';
import { isSidepaneOpen } from '../../../../../components/Sidepane/isSidepaneOpen';
import { TextWithEllipsisAndTooltip } from '../../../../../components/TextWithEllipsisAndTooltip';

type DiscoverFiltersBarProps = {
  setFilterValues: (values: string[]) => void;
};

export const DiscoverFiltersBar = ({ setFilterValues }: DiscoverFiltersBarProps) => {
  const [filters, setFilters] = useState<Filter[]>(discoverFilterList);
  const [filterToShow, setFilterToShow] = useState<Filter | null>(null);
  const urlParamsHash = useRef('');
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const getAlgoliaCredentialsQuery = useGetAlgoliaCredentialsQuery({ accountId });
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [showFiltersMenu, setShowFiltersMenu] = useState(false);
  const fPressed = useKeyPress('f');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!getAlgoliaCredentialsQuery.data) return;
    const { key, app_id, index_name } = getAlgoliaCredentialsQuery.data;
    getFilterOptions({ apiKey: key, applicationId: app_id, indexName: index_name })
      .then(setFilterOptions)
      .catch(e => {
        console.error(e);
        notify('Failed to get filters', 'error');
      });
  }, [getAlgoliaCredentialsQuery]);

  useEffect(() => {
    const values = filters.filter((f) => !f.isDisabled && f.isActive(f.value)).map((f) => f.getFilterString(f.value));
    setFilterValues(values);
  }, [filters, setFilterValues]);

  //On URL params change
  useEffect(() => {
    const hash = searchParams.toString();
    if (urlParamsHash.current !== hash) {
      urlParamsHash.current = hash;
      const newFilters = filters.map((f) => {
        const value = searchParams.get(f.name) || f.value;
        return { ...f, value };
      });
      if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
        setFilters(newFilters);
      }
    }
  }, [setFilters, filters, searchParams, setSearchParams]);

  //on "f" pressed
  useEffect(() => {
    if (fPressed[0] && !filterToShow && !isTypingInInput() && !isSidepaneOpen()) {
      setShowFiltersMenu(true);
    }
  }, [fPressed, filterToShow]);

  const removeFilter = (name: string) => {
    const newFilters = filters.map((f) => {
      if (f.name === name) {
        return { ...f, value: null };
      }
      return f;
    });
    updateFilters(newFilters);
  };

  const updateFilters = (newFilters: Filter[]) => {
    setFilters(newFilters);
    const newUrl = getNewUrlWithFilters(newFilters);
    const currentUrl = `${window.location.pathname}${window.location.search}`;
    if (newUrl !== currentUrl) {
      navigate(newUrl, { replace: true });
    }
  };

  return (
    <div className="flex items-center gap-4" data-test-id="discover-filter-bar">
      <div className="flex items-center gap-2">
        <div className="text-text-primary">Filter by</div>
        <div className="flex gap-2 flex-wrap">
          <ActiveFilters filters={filters} removeFilter={removeFilter} setFilterToShow={setFilterToShow} />
          <div className="relative">
            {
              showFiltersMenu ? (
                <DiscoverFiltersMenu filters={filters} setFilterToShow={setFilterToShow} onClose={() => setShowFiltersMenu(false)} />
              ) : (
                <div id="open-dropdown-menu-button" className="cursor-pointer rounded-lg bg-white p-1.5 text-text-primary hover:bg-slate-50 border-slate-200 border" onClick={() => setShowFiltersMenu(true)}>
                  <PlusIcon width="16" height="16" />
                </div>
              )
            }
            <FilterPanel
              filters={filters}
              setFilters={updateFilters}
              filterToShow={filterToShow}
              setFilterToShow={setFilterToShow}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface ActiveFiltersProps {
  filters: Filter[];
  removeFilter: (name: string) => void;
  setFilterToShow: (filter: Filter) => void;
}

const ActiveFilters = ({ filters, removeFilter, setFilterToShow }: ActiveFiltersProps) => {
  const filtersToShow = filters.filter((f) => !f.isDisabled && f.isActive(f.value));
  return (
    <>
      {filtersToShow.map((f) => (
        <div
          key={f.name}
          className="mb-auto mt-auto flex h-6 items-center justify-center justify-between gap-1 rounded-2xl bg-muse-100 px-1 text-muse-700">
          <div className="flex cursor-pointer items-center gap-1 whitespace-nowrap" onClick={() => setFilterToShow(f)}>
            {f.filterIcon}
            <TextWithEllipsisAndTooltip text={`${f.name}: ${f.presentValue(f.value)}`} maxChars={50}/>
          </div>
          <XMarkIcon
            width="16"
            height="16"
            className="ml-1 cursor-pointer text-muse-400 hover:text-muse-600"
            onClick={() => removeFilter(f.name)}
          />
        </div>
      ))}
    </>
  );
};

const getNewUrlWithFilters = (filters: Filter[]) => {
  const existingParams = new URLSearchParams(window.location.search);
  const newParams = new URLSearchParams();
  for (const filter of filters) {
    if (!filter.isDisabled && filter.isActive(filter.value)) {
      newParams.set(filter.name, filter.value || '');
    }
  }
  for (const [key, value] of existingParams.entries()) {
    if (!filters.some((f) => f.name === key)) {
      newParams.set(key, value);
    }
  }
  const newUrl = `${window.location.pathname}?${newParams.toString()}`;
  return newUrl;
};
