import { useState } from "react";
import { DiscoverFilterProps, Filter, SelectFilterOption } from "./Types";
import { SelectFilter } from "./filterComponents/SelectFilter";
import { store } from "../../../../../infrastructure/state/store";
import { searchFilterValues } from "../../../../../services/nodes/nodes";
import { accountsApi } from "../../../../../services/accounts";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { useSelector } from "react-redux";
import { LanguageIcon } from "@heroicons/react/24/outline";

export const NameFilter = (props: DiscoverFilterProps) => {
    const [options, setOptions] = useState<SelectFilterOption[]>([]);
    const accountId = useSelector(selectActiveAccountId);
    const onQueryChange = async (query: string) => {
        const options = await getNameOptions(query, accountId, props.filters || []);
        setOptions(options);
    };
    return (
        <SelectFilter
            label={'Name'}
            {...props}
            isMulti={true}
            withSearch={true}
            withInMemorySearch={false}
            options={options}
            onQueryChange={onQueryChange}
        />
    );
};

const getNameOptions = async (query: string, accountId: number, filters: Filter[]): Promise<SelectFilterOption[]> => {
    const { data: credentials, error } = await store.dispatch(accountsApi.endpoints.getAlgoliaCredentials.initiate({ accountId }));
    const typeFilter = filters.find(f => f.name === 'Type');
    const filtersSearchQuery = typeFilter?.value ? typeFilter.getFilterString(typeFilter.value) : '';
    
    if (!credentials) {
        console.error(error);
        return [];
    }
    else if (!query) {
        return [];
    }
    else {
        const nameFacetValues = await searchFilterValues({
            applicationId: credentials.app_id,
            apiKey: credentials.key,
            indexName: credentials.index_name,
            filterName: 'name',
            filterQuery: query,
            searchParameters: { filters: filtersSearchQuery }
        });
        return nameFacetValues.map(name => ({
            label: name,
            value: name,
            icon: <LanguageIcon width="16" height="16" />
        }));
    }
};