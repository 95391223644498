import {
  CalendarIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  UserCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import { capitalize } from '../../../../utils/stringsUtils';
import Input from '../../../../components/form/Input';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import DropdownMenu from '../../../../components/DropdownMenu';
import Select, { Option } from '../../../../components/form/Select';
import { IFilter, SetFilters, SortOptions } from '../Types';
import { useClickAway } from 'react-use';
import DateFilterPanel from './DateFilterPanel';
import OwnerFilterPanel from './OwnerFilterPanel';
import { dateFilterOptions } from './dateFilter';
import { IProject } from '../../../projects/IProject';

const getMenuItems = (setActiveFilter: (filter: string) => void) => {
  const filterOptions = [
    {
      name: 'dates',
      icon: <CalendarIcon width="16" height="16" className="mr-2 text-slate-400" />
    },
    {
      name: 'owner',
      icon: <UserCircleIcon width="16" height="16" className="mr-2 text-slate-400" />
    }
  ];
  const menuItems = filterOptions.map((filter) => {
    return {
      name: capitalize(filter.name),
      icon: filter.icon,
      onClick: () => setActiveFilter(filter.name),
      className: 'text-slate-600 bg-white hover:bg-slate-50'
    };
  });
  return menuItems;
};

interface EvolutionFiltersBarProps {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  sortBy: string;
  setSortBy: (sortBy: SortOptions) => void;
  filters: IFilter;
  setFilters: SetFilters;
  projects: IProject[];
  selectedProjectId: number | null;
  setSelectedProjectId: (projectId: number) => void;
}

const EvolutionFiltersBar = ({
  searchInput,
  setSearchInput,
  sortBy,
  setSortBy,
  filters,
  setFilters,
  projects,
  selectedProjectId,
  setSelectedProjectId
}: EvolutionFiltersBarProps) => {
  const [activeFilter, setActiveFilter] = useState('');
  const menuItems = useMemo(() => getMenuItems(setActiveFilter), [setActiveFilter]);
  const [delayedSearchInput, setDelayedSearchInput] = useState(searchInput);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchInput(delayedSearchInput);
    }, 300);
    return () => clearTimeout(timeout);
  }, [delayedSearchInput, setSearchInput]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center text-text-primary gap-1">
          <div>Filter by</div>
          <FiltersBadges filters={filters} setFilters={setFilters} />
          <DropdownMenu items={menuItems}>
            <div id="open-dropdown-menu-button" className="cursor-pointer rounded-lg bg-white p-1 text-text-primary hover:bg-slate-50 border-slate-200 border">
              <PlusIcon width="14" height="14" />
            </div>
          </DropdownMenu>
          <div className="relative">
            <FilterPanelContainer
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        </div>
        <div className="flex items-center">
          <Input
            placeholder="Search"
            onInputChange={(e: ChangeEvent<HTMLInputElement>) => setDelayedSearchInput(e.target.value)}
            value={delayedSearchInput}
            icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
            height="h-8"
            background="bg-slate-100"
            border="border-slate-100"
            shadow=""
            rounded="rounded-2xl"
            highlightBorder=""
          />
          <div>
            <Select
              options={['newest', 'oldest'].map((option) => ({ value: option, label: capitalize(option) }))}
              value={sortBy}
              onChange={(option) => setSortBy((option as Option).value as SortOptions)}
              className="w-28 bg-surface-light"
              background="#F8FAFC"
              isSearchable={false}
              menuPortalDistance="-15px"
            />
          </div>
          <div className="flex items-center text-text-primary">
            Project:
            <Select
              options={projects.map((project) => ({ value: project.id, label: project.name }))}
              value={selectedProjectId}
              onChange={(option) => setSelectedProjectId((option as Option).value as number)}
              className="w-48 bg-surface-light"
              background="#F8FAFC"
              isSearchable={false}
              menuPortalDistance="-15px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FiltersBadges = ({ filters, setFilters }: { filters: IFilter; setFilters: SetFilters }) => {
  const removeFilter = (filter: string) => {
    const newFilters = { ...filters, [filter]: '' };
    setFilters(newFilters);
  };
  const getDateLabelByValue = (value: string) => {
    return dateFilterOptions.find((option) => option.value === value)?.label;
  };
  return (
    <div className="flex items-center gap-1">
      {filters.createdAt && (
        <div className="flex items-center rounded-full bg-muse-100 px-2 py-1">
          <CalendarIcon width="16" height="16" className="text-muse-400" />
          <div className="ml-2 text-sm text-muse-600">{getDateLabelByValue(filters.createdAt)}</div>
          <div className="ml-1 cursor-pointer" onClick={() => removeFilter('createdAt')}>
            <XMarkIcon width="16" height="16" className="text-muse-400 hover:text-muse-500" />
          </div>
        </div>
      )}
      {filters.owner && (
        <div className="flex items-center rounded-full bg-muse-100 px-2 py-1">
          <UserCircleIcon width="16" height="16" className="text-muse-400" />
          <div className="ml-2 text-sm text-muse-600">{filters.owner}</div>
          <div className="ml-1 cursor-pointer" onClick={() => removeFilter('owner')}>
            <XMarkIcon width="16" height="16" className="text-muse-400 hover:text-muse-500" />
          </div>
        </div>
      )}
    </div>
  );
};

interface FilterPanelContainerProps {
  activeFilter: string;
  setActiveFilter: (filter: string) => void;
  filters: IFilter;
  setFilters: SetFilters;
}

const FilterPanelContainer = ({ activeFilter, setActiveFilter, filters, setFilters }: FilterPanelContainerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => setActiveFilter(''));
  const setFiltersAndClose = (filters: IFilter) => {
    setFilters(filters);
    setActiveFilter('');
  };
  if (!activeFilter) {
    return null;
  }

  return (
    <div
      className={`top-4 -left-8 absolute z-20 self-start	rounded-md border border-slate-200 bg-white shadow`}
      ref={ref}>
      {activeFilter === 'dates' && <DateFilterPanel filters={filters} setFilters={setFiltersAndClose} />}
      {activeFilter === 'owner' && <OwnerFilterPanel filters={filters} setFilters={setFiltersAndClose} />}
    </div>
  );
};

export default EvolutionFiltersBar;
