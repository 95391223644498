import { TagIcon, FolderIcon, ArrowLongRightIcon, InformationCircleIcon, CodeBracketIcon } from '@heroicons/react/24/solid';
import { useState, useEffect, useMemo } from 'react';
import { BranchIcon, GithubIcon, LookerIcon } from '../../../../assets/images/icons/DelphiIcons';
import Button from '../../../../components/button/Button';
import { ButtonTypes } from '../../../../components/button/types';
import Select, { Option } from '../../../../components/form/Select';
import { useGetRepositoryBranchesQuery } from '../../../../services/repositories';
import { useGetRepositoriesQuery } from '../../../../services/users';
import IRepository from '../../../projects/IRepository';
import getProjectOrganization from '../../../projects/getProjectOrganization';
import IBranch, { IBranchSyncConfiguration } from '../../IBranch';
import Input from '../../../../components/form/Input';
import { extractErrorMessage } from '../../../../services/api';
import RadioButton from '../../../../components/form/RadioButton';
import { Tooltip } from 'react-tooltip';
import { SQLDialect } from '../../../projects/IProject';

interface SyncConfigurationFormProps {
  onClose: () => void;
  branch: IBranch;
  showPreview: (syncConfiguration: IBranchSyncConfiguration) => void;
}

const SyncConfigurationForm = ({ onClose, branch, showPreview }: SyncConfigurationFormProps) => {
  const [targetRepository, setTargetRepository] = useState<IRepository | null>(null);
  const [targetBranch, setTargetBranch] = useState('');
  const [directory, setDirectory] = useState('/dbt_sync');
  const [sqlDialect, setSqlDialect] = useState<SQLDialect>('snowflake');
  const [tags, setTags] = useState<string[]>([]);
  const [withPr, setWithPr] = useState(true);
  const [error, setError] = useState('');

  const onNext = () => {
    const newSyncConfiguration = {
      targetBranch: targetBranch,
      targetRepository: targetRepository?.cloneUrl || '',
      targetDirectory: directory,
      tags,
      withPr: withPr,
      sourceBranch: branch.name,
      sqlDialect: sqlDialect
    };
    const errorMesssage = verifySyncConfiguration(newSyncConfiguration);
    if (errorMesssage) {
      setError(errorMesssage);
    } else {
      showPreview(newSyncConfiguration);
    }
  };

  useEffect(() => {
    setError('');
  }, [targetRepository, targetBranch, directory, tags, withPr, setError]);

  return (
    <div className="flex flex-col gap-2 text-secondary">
      <ProcessExplainer branch={branch} />
      <RepositorySelection targetRepository={targetRepository} setTargetRepository={setTargetRepository} />
      <BranchSelection
        targetBranch={targetBranch}
        setTargetBranch={setTargetBranch}
        targetRepository={targetRepository}
      />
      <DirectorySelection directory={directory} setDirectory={setDirectory} />
      <SQLDialectSelection sqlDialect={sqlDialect} setSqlDialect={setSqlDialect} />
      <TagsSelection tags={tags} setTags={setTags} />
      <WithPrSelection withPr={withPr} setWithPr={setWithPr} />
      {error && <span className="text-sm text-danger">{error}</span>}
      <div className="ml-auto mt-5 flex w-fit gap-2">
        <Button type={ButtonTypes.secondary} onClick={onClose} className="w-36" text="Cancel" />
        <Button type={ButtonTypes.primary} onClick={onNext} className="w-36" text="Next" />
      </div>
    </div>
  );
};

const ProcessExplainer = ({ branch }: { branch: IBranch }) => {
  return (
    <div className="mb-3 rounded border border-slate-200 bg-surface-light py-4">
      <div className="justify-centertext-text-primary mx-auto flex w-fit">
        <div className="flex w-40 items-center justify-center gap-1 rounded-full border border-slate-200 bg-white py-1.5">
          <BranchIcon width="14" height="14" fill="#0A225C" />
          {branch.name} branch
        </div>
        <div className="mx-0.5 my-auto flex text-surface-primary">
          <ArrowLongRightIcon width="22" height="22" />
        </div>
        <div className="flex w-40 items-center justify-center gap-1 rounded-full border border-slate-200 bg-white py-1.5">
          <LookerIcon width="16" height="16" />
          LookML Repository
        </div>
      </div>
      <div className="mt-4 text-center text-sm text-tertiary">
        Configure LookML destination to automatically keep your Looker model consistent with a branch of your dbt
        project.
      </div>
    </div>
  );
};

const verifySyncConfiguration = (syncConfiguration: IBranchSyncConfiguration) => {
  if (!syncConfiguration.targetRepository) {
    return 'Target repository is required';
  }
  if (!syncConfiguration.targetBranch) {
    return 'Target branch is required';
  }
  if (!syncConfiguration.targetDirectory) {
    return 'Target directory is required';
  }
  return '';
};

const WithPrSelection = ({ withPr, setWithPr }: { withPr: boolean; setWithPr: (withPr: boolean) => void }) => {
  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex gap-2">
        <div className="mt-1">
          <RadioButton value={withPr} setValue={() => setWithPr(true)} />
        </div>
        <div>
          <div>Create pull request</div>
          <div className="text-sm text-tertiary">Changes will need to be merged to take effect.</div>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="mt-1">
          <RadioButton value={!withPr} setValue={() => setWithPr(false)} />
        </div>
        <div>
          <div>Commit to branch</div>
          <div className="text-sm text-tertiary">Changes will automatically be pushed without review.</div>
        </div>
      </div>
    </div>
  );
};

const TagsSelection = ({ tags, setTags }: { tags: string[]; setTags: (tags: string[]) => void }) => {
  const tagOptions = tags.map((tag) => ({ label: tag, value: tag }));
  const onChange = (options: Option | Option[]) => {
    setTags((options as Option[]).map((option) => option.value.toString()));
  };
  return (
    <div className="flex items-center">
      <div className="w-52">Selective sync</div>
      <Select
        value={tags}
        options={tagOptions}
        placeholder="Select tags"
        isCreatable
        isMulti
        onChange={onChange}
        className="border-grey-300 w-full rounded-md border shadow"
        icon={<TagIcon width="16" height="16" className="text-gray-400" />}
      />
    </div>
  );
};

const DirectorySelection = ({
  directory,
  setDirectory
}: {
  directory: string;
  setDirectory: (directory: string) => void;
}) => {
  return (
    <div className="flex items-center">
      <div className="flex w-52 gap-1">
        Target directory
        <InformationCircleIcon
          width="14"
          height="14"
          className="cursor-help text-slate-400"
          id="target-directory-helper"
        />
      </div>
      <Input
        value={directory}
        onInputChange={(e) => setDirectory(e.target.value)}
        placeholder="Directory"
        icon={<FolderIcon width="16" height="16" className="text-gray-400" />}
      />
      <Tooltip anchorSelect="#target-directory-helper" className="!w-60">
        The target directory where Delphi will synchronize LookML changes. If no target directory exists, Delphi will
        create it during the initial sync.
      </Tooltip>
    </div>
  );
};

interface BranchSelectionProps {
  targetBranch: string;
  setTargetBranch: (branch: string) => void;
  targetRepository: IRepository | null;
}

const BranchSelection = ({ targetBranch, setTargetBranch, targetRepository }: BranchSelectionProps) => {
  const branchesQuery = useGetRepositoryBranchesQuery(
    {
      repository: targetRepository?.name || '',
      organization: getProjectOrganization(targetRepository?.cloneUrl || '')
    },
    {
      skip: !targetRepository
    }
  );
  const branchOptions = useMemo(
    () =>
      branchesQuery.data?.map((branch) => ({
        label: branch.name,
        value: branch.name
      })) || [],
    [branchesQuery.data]
  );

  useEffect(() => {
    const defaultTargetBranchIfPresent = ['main', 'master'];
    if (targetRepository) {
      for (const branch of defaultTargetBranchIfPresent) {
        if (branchesQuery.data?.find((b) => b.name === branch)) {
          setTargetBranch(branch);
          return;
        }
      }
      setTargetBranch('');
    }
  }, [targetRepository, setTargetBranch, branchesQuery.data]);

  const onBranchSelection = (option: Option | Option[]) => {
    const branchName = (option as Option).value.toString();
    setTargetBranch(branchName);
  };
  return (
    <div className="flex items-center">
      <div className="w-52">Target branch</div>
      <Select
        options={branchOptions}
        value={targetBranch}
        onChange={onBranchSelection}
        placeholder="Select a target branch"
        className="border-grey-300 w-full rounded-md border shadow"
        isLoading={branchesQuery.isLoading}
        icon={<BranchIcon fill="#94A3B8" width="16" height="16" />}
        dataTestId="target-branch-select"
      />
    </div>
  );
};

interface SQLDialectSelectionProps {
  sqlDialect: SQLDialect;
  setSqlDialect: (dialect: SQLDialect) => void;
}

const sqlDialectOptions = [
  { label: 'spark', value: 'spark' },
  { label: 'databricks', value: 'databricks' },
  { label: 'snowflake', value: 'snowflake' },
  { label: 'presto', value: 'presto' }
];

const SQLDialectSelection = ({ sqlDialect, setSqlDialect }: SQLDialectSelectionProps) => {
  const onSqlDialectSelected = (option: Option | Option[]) => {
    const sqlDialect = (option as Option).value as SQLDialect;
    setSqlDialect(sqlDialect);
  };

  return (
    <>
      <div className="flex items-center">
        <div className="w-52">SQL dialect</div>
        <Select
          options={sqlDialectOptions}
          value={sqlDialect}
          onChange={onSqlDialectSelected}
          placeholder="Select an SQL dialect"
          className="border-grey-300 w-full rounded-md border shadow"
          icon={<CodeBracketIcon width="16" height="16" fill="#94A3B8" />}
          dataTestId="target-sql-dialect-select"
        />
      </div>
    </>
  );
};

interface RepositorySelectionProps {
  targetRepository: IRepository | null;
  setTargetRepository: (repository: IRepository | null) => void;
}

const RepositorySelection = ({ targetRepository, setTargetRepository }: RepositorySelectionProps) => {
  const repositories = useGetRepositoriesQuery();
  const repositoryOptions = useMemo(
    () =>
      repositories.data?.map((repository) => ({
        label: repository.cloneUrl,
        value: repository.id
      })) || [],
    [repositories.data]
  );
  const onRepositorySelection = (option: Option | Option[]) => {
    const repositoryId = (option as Option).value;
    const repository = repositories.data?.find((repository) => repository.id === repositoryId);
    setTargetRepository(repository || null);
  };

  return (
    <>
      <div className="flex items-center">
        <div className="w-52">Target repository</div>
        <Select
          options={repositoryOptions}
          value={targetRepository?.id || ''}
          onChange={onRepositorySelection}
          placeholder="Select a target repository"
          className="border-grey-300 w-full rounded-md border shadow"
          isDisabled={repositories.isError}
          isLoading={repositories.isLoading}
          icon={<GithubIcon width="16" height="16" fill="#94A3B8" />}
          dataTestId="target-repository-select"
        />
      </div>
      {repositories.isError && (
        <span className="text-sm text-danger">{`Error loading repositories: ${extractErrorMessage(repositories.error).message}`}</span>
      )}
    </>
  );
};

export default SyncConfigurationForm;
