import { TableauIcon } from "../../../../../assets/images/icons/DelphiIcons";
import Button from "../../../../../components/button/Button";
import { ButtonTypes } from "../../../../../components/button/types";
import { IExpandedNode, NodeType } from "../../INode";
import { NodeButtons } from "../NodeSidepaneButtons";
import { NodeButtonProps } from "../types";

export const buttonsWithWorkbook = (node: IExpandedNode) => {
    const workbookId = node.chainedNodes?.find(n => n.type === NodeType.TableauWorkbook)?.id;
    const ButtonsComponent =  (props: NodeButtonProps) => (
        <NodeButtons {...props}>
            <Button
                type={ButtonTypes.secondary}
                text="View workbook"
                icon={<TableauIcon width="16" height="16" />}
                className="w-full"
                onClick={() => {
                    if (workbookId && props.onRelatedNodeClick) {
                        props.onRelatedNodeClick(workbookId);
                    }
                }}
            />
        </NodeButtons>
    );
    return ButtonsComponent;
};