import { IChange, IChangePage } from "../../features/evolution/IChange";
import { CreateChange } from "../../features/evolution/createChangeSidepane/types";
import { dateFilterToTimeRange } from "../../features/evolution/changesPage/filters/dateFilter";
import { Operation } from "../../features/operations/Operation";
import api from "../api";
import { statusToState, applicationToCreationContextType, transformChangeResponse, transformChangeToRequest, transformCreateChangeToRequest, transformChangePageResponse } from "./transformers";
import { GetChangesPageResponse, GetChangesRequest } from "./types";

export const changesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChanges: build.query<{ items: IChangePage[], total: number }, GetChangesRequest>({
      query: ({ projectId, sortBy = 'newest', statuses, application, search = '', page = 1, pageSize = 100, owner = '', createdAt = null }) => {
        const { start: created_after, end: created_before } = dateFilterToTimeRange(createdAt);
        return {
          url: `projects/${projectId}/changes`,
          params: {
            sort: sortBy,
            state: statuses.map((status) => statusToState[status]).join(','),
            search,
            page,
            page_size: pageSize,
            created_by_email: owner,
            creation_context_type: applicationToCreationContextType[application],
            created_after,
            created_before
          }
        };
      },
      providesTags: ['Changes'],
      transformResponse: (response: { items: GetChangesPageResponse[], total: number }) => ({
        items: response.items.map(transformChangePageResponse),
        total: response.total
      })
    }),
    proposeChangeToDataModel: build.mutation<void, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/propose`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    getChange: build.query<IChange, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}`
        };
      },
      providesTags: ['Changes'],
      transformResponse: transformChangeResponse,
      keepUnusedDataFor: 0
    }),
    getChangeInAccountContext: build.query<IChange, { accountId: number; changeId: number }>({
      query(data) {
        return {
          url: `accounts/${data.accountId}/changes/${data.changeId}`
        };
      },
      providesTags: ['Changes'],
      transformResponse: transformChangeResponse,
      keepUnusedDataFor: 0
    }),
    updateChange: build.mutation<void, { projectId: number; changeId: number; change: IChange }>({
      query: ({ projectId, changeId, change }) => {
        return {
          url: `projects/${projectId}/changes/${changeId}`,
          method: 'PATCH',
          body: transformChangeToRequest(change)
        };
      },
      invalidatesTags: ['Changes']
    }),
    createPullRequest: build.mutation<Operation, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/create_pull_request`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    rejectChange: build.mutation<void, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/reject`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    generateChangeDiff: build.mutation<Operation, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/generate_diff`,
          method: 'POST'
        };
      }
    }),
    createChange: build.mutation<IChange, { projectId: number; change: CreateChange }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes`,
          method: 'POST',
          body: transformCreateChangeToRequest(data.change)
        };
      },
      invalidatesTags: ['Changes'],
      transformResponse: transformChangeResponse
    })
  })
});

export const {
  useGetChangesQuery,
  useProposeChangeToDataModelMutation,
  useGetChangeQuery,
  useLazyGetChangeQuery,
  useUpdateChangeMutation,
  useCreatePullRequestMutation,
  useRejectChangeMutation,
  useGenerateChangeDiffMutation,
  useGetChangeInAccountContextQuery,
  useCreateChangeMutation
} = changesApi;
