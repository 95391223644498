import api from '../api';
import { IDbtCloudJob, IProject, IProjectConfiguration } from '../../features/projects/IProject';
import IStakeholder from '../../features/stakeholders/IStakeholder';
import IBuild from '../../features/builds/IBuild';
import HealthType from '../../features/projects/HealthType';
import IBranch from '../../features/branches/IBranch';
import { Operation } from '../../features/operations/Operation';
import {
  ProjectResponse,
  CreateProjectRequest,
  DeleteProjectRequest,
  RenameProjectRequest,
  GetStakeholdersRequest,
  StakeholdersResponse,
  SetProjectStakeholdersRequest,
  GetProjectBuildReponse,
  ExpandedProject,
  DryRunResponse
} from './types';
import { transformBuildResponseToLocalBuild, transformProjectResponseToLocalProject } from './transformers';

const transformOutgoingProjectConfiguration = (configuration: IProjectConfiguration) => {
  return {
    delphi_schema_version: configuration.schemaVersion,
    changes: {
      pull_request: {
        title: configuration.changes.pullRequest.title,
        body: configuration.changes.pullRequest.body,
        branch_name: configuration.changes.pullRequest.branchName
      },
      commit_message: configuration.changes.commitMessage
    },
    data_model_sync: configuration.dataModelSync.map((sync, index) => ({
      name: `sync_${index}`,
      source_branch: sync.sourceBranch,
      destination_type: 'lookml',
      selector: {
        tags: sync.tags
      },
      destination_parameters: {
        target_repository: sync.targetRepository,
        target_branch: sync.targetBranch,
        target_directory: sync.targetDirectory,
        with_pr: sync.withPr,
        target_sql_dialect: sync.sqlDialect || 'snowflake'
      }
    }))
  };
};

export const projectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query<IProject, number>({
      query: (id) => `projects/${id}`,
      providesTags: ['Projects'],
      transformResponse: transformProjectResponseToLocalProject
    }),
    getProjects: build.query<IProject[], void>({
      query: () => 'projects',
      providesTags: ['Projects'],
      transformResponse: (response: ProjectResponse[]) => response.map(transformProjectResponseToLocalProject)
    }),
    createProject: build.mutation<void, CreateProjectRequest>({
      query(data) {
        return {
          url: `folders/${data.folder_id}/projects`,
          method: 'POST',
          body: data.project
        };
      },
      invalidatesTags: ['Projects']
    }),
    deleteProject: build.mutation<void, DeleteProjectRequest>({
      query(data) {
        return {
          url: `projects/${data.id}`,
          method: 'DELETE',
          body: data
        };
      },
      invalidatesTags: ['Projects']
    }),
    renameProject: build.mutation<void, RenameProjectRequest>({
      query(data) {
        return {
          url: `projects/${data.id}/rename`,
          method: 'POST',
          body: { name: data.name }
        };
      },
      invalidatesTags: ['Projects']
    }),
    getProjectStakeholders: build.query<IStakeholder[], GetStakeholdersRequest>({
      query: (data) => `projects/${data.id}/stakeholders`,
      providesTags: ['Stakeholders'],
      transformResponse: (response: StakeholdersResponse) => {
        return [...response.stakeholders, ...response.inherited_stakeholders];
      }
    }),
    setProjectStakeholders: build.mutation<void, SetProjectStakeholdersRequest>({
      query(data) {
        return {
          url: `projects/${data.id}/stakeholders`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Stakeholders']
    }),
    getProjectBuilds: build.query<IBuild[], { id: number }>({
      query: (data) => `projects/${data.id}/builds`,
      providesTags: ['Builds'],
      transformResponse: (response: GetProjectBuildReponse[]) => {
        return response.map(transformBuildResponseToLocalBuild);
      }
    }),
    getProjectHealth: build.query<HealthType, { id: number }>({
      query: (data) => `projects/${data.id}/health`
    }),
    getProjectBranches: build.query<IBranch[], { id: number }>({
      query: (data) => `projects/${data.id}/branches`,
      providesTags: ['Branches']
    }),
    connectProjectBranch: build.mutation<void, { projectId: number; branchName: string }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/branches`,
          method: 'POST',
          body: { name: data.branchName }
        };
      },
      invalidatesTags: ['Branches']
    }),
    setDefaultTargetBranch: build.mutation<void, { projectId: number, branchId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/default-target-branch/${data.branchId}`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Projects']
    }),
    getProjectConfiguration: build.query<IProjectConfiguration, { projectId: number }>({
      query: ({ projectId }) => `projects/${projectId}/expanded`,
      providesTags: ['Projects'],
      transformResponse: (response: ExpandedProject) => ({
        schemaVersion: response.configuration.delphi_schema_version,
        changes: {
          pullRequest: {
            title: response.configuration.changes.pull_request.title,
            body: response.configuration.changes.pull_request.body,
            branchName: response.configuration.changes.pull_request.branch_name
          },
          commitMessage: response.configuration.changes.commit_message
        },
        dataModelSync: response.configuration.data_model_sync.map((sync) => ({
          sourceBranch: sync.source_branch,
          targetRepository: sync.destination_parameters.target_repository,
          targetBranch: sync.destination_parameters.target_branch,
          targetDirectory: sync.destination_parameters.target_directory,
          tags: sync.selector.tags,
          withPr: sync.destination_parameters.with_pr,
          sqlDialect: sync.destination_parameters.target_sql_dialect || 'snowflake',
        })),
        health: response.health,
        changesCount: response.changes_count || 0,
        lastSuccessfulBuild: response.last_successful_build
          ? transformBuildResponseToLocalBuild(response.last_successful_build)
          : null
      })
    }),
    createProjectConfigurationDiffPreview: build.mutation<
      DryRunResponse,
      { projectId: number; configuration: IProjectConfiguration }
    >({
      query({ projectId, configuration }) {
        return {
          url: `projects/${projectId}/configuration/dry_run`,
          method: 'POST',
          body: transformOutgoingProjectConfiguration(configuration)
        };
      }
    }),
    updateProjectConfiguration: build.mutation<Operation, { projectId: number; configuration: IProjectConfiguration }>({
      query: ({ projectId, configuration }) => {
        return {
          url: `projects/${projectId}/configuration/commit`,
          method: 'POST',
          body: transformOutgoingProjectConfiguration(configuration)
        };
      }
    }),
    updateProject: build.mutation<void, { projectId: number; dbtCloudDeploymentJob: IDbtCloudJob }>({
      query: ({ projectId, dbtCloudDeploymentJob }) => {
        return {
          url: `projects/${projectId}`,
          method: 'PATCH',
          body: {
            dbt_cloud_job: dbtCloudDeploymentJob
          }
        };
      },
      invalidatesTags: ['Projects']
    }),
    getAccountProjects: build.query<IProject[], { accountId: number }>({
      query: ({ accountId }) => `account/${accountId}/projects`,
      providesTags: ['Projects'],
      transformResponse: (response: ProjectResponse[]) => response.map(transformProjectResponseToLocalProject)
    })
  })
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useRenameProjectMutation,
  useGetProjectQuery,
  useGetProjectStakeholdersQuery,
  useSetProjectStakeholdersMutation,
  useGetProjectBuildsQuery,
  useGetProjectHealthQuery,
  useGetProjectBranchesQuery,
  useSetDefaultTargetBranchMutation, 
  useConnectProjectBranchMutation,
  useGetProjectConfigurationQuery,
  useCreateProjectConfigurationDiffPreviewMutation,
  useUpdateProjectConfigurationMutation,
  useUpdateProjectMutation,
  useGetAccountProjectsQuery
} = projectsApi;
