import Layout from '../../components/layout/Layout';
import TopBar from '../../components/layout/TopBar';
import IntegrationKeysSection from './IntegrationKeysSection';
import IntegrationsSection from './integrations/IntegrationsSection';
import AccountOperations from './AccountOperations';
import Footer from '../../components/layout/Footer';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useGetAccountsQuery } from '../../services/accounts';
import { PageTabs } from '../../components/PageTabs';
import { useState } from 'react';


const Account = () => {
  const accountId = useSelector(selectActiveAccountId);
  const getAccountsQuery = useGetAccountsQuery();
  const accountName = getAccountsQuery.data?.find(account => account.id === accountId)?.name || '';
  const [tab, setTab] = useState<'integrations' | 'operations'>('integrations');

  return (
    <Layout>
      <TopBar>
        <div className="font-medium text-primary">{accountName}</div>
        <PageTabs
          tabs={[
            { name: 'Integrations', isActive: tab === 'integrations', onClick: () => setTab('integrations') },
            { name: 'Operations log', isActive: tab === 'operations', onClick: () => setTab('operations') }
          ]}
        />
      </TopBar>
      <div className="mx-24 min-h-[94vh] px-16 py-8 flex flex-col">
        {
          tab === 'integrations' ? (
            <>
              <IntegrationsSection />
              <IntegrationKeysSection />
            </>
          ) : (
            <AccountOperations />
          )
        }
        <Footer />
      </div>
    </Layout>
  );
};

export default Account;
