import { combineReducers } from 'redux';
import api from '../../services/api';
import activeProjectSlice from './slices/activeProjectSlice';
import activeAccountSlice from './slices/activeAccountSlice';
import isMenuCollpasedSlice from './slices/isMenuCollpasedSlice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  activeProject: activeProjectSlice.reducer,
  activeAccount: activeAccountSlice.reducer,
  isMenuCollapsed: isMenuCollpasedSlice.reducer,
});

export default rootReducer;
