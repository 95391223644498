import { GithubIcon } from '../../assets/images/icons/DelphiIcons';

const repositoryProviders = {
    github: {
        name: 'github',
        displayName: 'GitHub',
        icon: GithubIcon,
    }
};

export default repositoryProviders;