import { useEffect, useMemo } from "react";
import PageLoader from "../../../components/loaders/PageLoader";
import { getNodes } from "../../../services/nodes/nodes";
import { useGetAlgoliaCredentialsQuery } from "../../../services/accounts";
import { useNavigate } from 'react-router-dom';
import { getNodeUrl } from "./getNodeUrl";
import { notify } from "../../../components/Toaster";
import { Toaster } from "react-hot-toast";
import { ISuperficialNode } from "./INode";

export const NodeLookup = () => {
    const params = useMemo(() => new URLSearchParams(window.location.search), []);
    const getAlgoliaCredentialsQuery = useGetAlgoliaCredentialsQuery({ accountId: parseInt(params.get('account_id') || '') });
    const navigate = useNavigate();

    useEffect(() => {
        if (getAlgoliaCredentialsQuery.data) {
            const { key, app_id, index_name } = getAlgoliaCredentialsQuery.data;
            const filters = `unique_id:${params.get('unique_id')} AND type:${params.get('type')} AND dbt_project_name:${params.get('dbt_project')}`;
            getNodes({
                apiKey: key,
                applicationId: app_id,
                indexName: index_name,
                pageSize: 1,
                filters
            }).then(({ items }: { items: ISuperficialNode[] }) => {
                const node = items[0];
                if (!node) {
                    notify('Node not found', 'error');
                    return;
                }
                const column = node.subnodes.find(c => c.name === params.get('column'));
                const nodeUrl = getNodeUrl(node.id, column?.type, column?.name);
                navigate(nodeUrl);
            });
        }
    }, [getAlgoliaCredentialsQuery, navigate, params]);
    return <>
        <PageLoader />
        <Toaster />
    </>;
};