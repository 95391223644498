import { CollapseRightIcon } from "../../../../../assets/images/icons/DelphiIcons";
import Sidepane from "../../../../../components/Sidepane/Sidepane";
import { ISuperficialSubnode, SubnodeType } from "../../INode";
import PageLoader from "../../../../../components/loaders/PageLoader";
import { useGetExpandedNodeQuery } from "../../../../../services/nodes/nodes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { SubnodeSidepane } from "./SubnodeSidepane";
import { useState } from "react";
import { MetricSidepane } from "./MetricSidepane";

type NodeSidepaneLoaderProps = {
    nodeId: string | null;
    subnode: ISuperficialSubnode | null;
    isOpen: boolean;
    onClose: () => void;
}

export const SubNodeSidepaneLoader = ({ nodeId, subnode, isOpen, onClose }: NodeSidepaneLoaderProps) => {
    const [canCloseModal, setCanCloseModal] = useState<boolean>(true);
    const accountId = useSelector(selectActiveAccountId);
    const getExpandedNode = useGetExpandedNodeQuery({ accountId, nodeId: nodeId || '' }, { skip: !nodeId || !subnode });
    const expandedNode = getExpandedNode.data;
    const expandedSubnode = expandedNode?.subnodes.find(s => s.name === subnode?.name && s.type === subnode?.type);
    const isLoading = nodeId !== expandedNode?.id;
    const onCloseClick = () => canCloseModal && onClose();
    const SubnodeComponent = getSidepaneComponentByType(subnode?.type || null);

    return (
        <Sidepane isOpen={isOpen} onClose={onCloseClick}>
            <div className="w-[22rem] h-full flex flex-col">
                <div className="w-fit cursor-pointer p-1" onClick={onCloseClick}>
                    <CollapseRightIcon width="16" height="16" fill="#94A3B8" />
                </div>
                {
                    isLoading && <PageLoader />
                }
                {
                    !isLoading && expandedNode && expandedSubnode && <SubnodeComponent setCanCloseModal={setCanCloseModal} subnode={expandedSubnode} parentNode={expandedNode} />
                }
            </div>
        </Sidepane>
    );
};

const getSidepaneComponentByType = (type: SubnodeType | null) => {
    switch (type) {
        case SubnodeType.Metric:
            return MetricSidepane;
        default:
            return SubnodeSidepane;
    }
};