import { NewAggregateTableChangeColumnType, NewAggregateTableChangeData } from "../types";

export const validateNewAggregateTableChange = (changeData: NewAggregateTableChangeData): string | null => {
    if (!changeData.newTableName) {
        return 'Model name must be set';
    }
    if (!changeData.columns.some(c => c.type === NewAggregateTableChangeColumnType.metric)) {
        return 'At least one metric needs to selected';
    }
    if (changeData.date.start && !changeData.date.end) {
        return 'If start time is set, then end time must also be set';
    }
    if (!changeData.date.start && changeData.date.end) {
        return 'If end time is set, then start time must also be set';
    }
    return null;
};