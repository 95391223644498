export type DateFilterValue = 'last7Days' | 'last30Days' | 'last3Months' | null;

export const dateFilterOptions: { label: string; value: DateFilterValue }[] = [
  {
    label: 'Last 7 Days',
    value: 'last7Days'
  },
  {
    label: 'Last 30 Days',
    value: 'last30Days'
  },
  {
    label: 'Last 3 Months',
    value: 'last3Months'
  }
];

export const dateFilterToTimeRange = (dateFilter: DateFilterValue) => {
  const now = new Date();
  const nowTimestamp = now.getTime();
  switch (dateFilter) {
    case 'last7Days':
      return {
        start: new Date(nowTimestamp - 7 * 24 * 60 * 60 * 1000).toISOString(),
        end: now.toISOString()
      };
    case 'last30Days':
      return {
        start: new Date(nowTimestamp - 30 * 24 * 60 * 60 * 1000).toISOString(),
        end: now.toISOString()
      };
    case 'last3Months':
      return {
        start: new Date(nowTimestamp - 3 * 30 * 24 * 60 * 60 * 1000).toISOString(),
        end: now.toISOString()
      };
    default:
      return {
        start: undefined,
        end: undefined
      };
  }
};
