import DataTable from 'react-data-table-component';
import type { TableColumn, TableRow } from 'react-data-table-component';
import { PaginationProps, TableColumnProps } from './types';
import { useMemo } from 'react';
import { getTableStyles } from './getTableStyles';

interface TableProps {
  data: unknown[];
  pagination?: PaginationProps;
  isLoading?: boolean;
  columns: TableColumnProps[];
  onRowClicked?: (row: unknown) => void;
  maxBodyHeight?: string;
  className?: string;
}

const Table = ({ data, columns, onRowClicked, pagination, isLoading = false, maxBodyHeight = '80vh', className = '' }: TableProps) => {
  const styles = useMemo(() => getTableStyles(!!onRowClicked), [onRowClicked]);
  return (
    <div>
      <div className={`rounded-md border shadow ${className}`}>
        <DataTable
          columns={columns}
          data={data as TableRow[]}
          customStyles={styles}
          striped
          onRowClicked={(row: TableRow) => onRowClicked && onRowClicked(row as unknown)}
          progressPending={isLoading}
          pagination={!!pagination}
          paginationServer={!!pagination}
          paginationTotalRows={pagination?.total}
          onChangeRowsPerPage={pagination?.setPageSize}
          onChangePage={pagination?.setPage}
          paginationPerPage={pagination?.pageSize}
          fixedHeader
          fixedHeaderScrollHeight={maxBodyHeight}
        />
      </div>
    </div>
  );
};

export type { TableColumn, TableRow };
export default Table;
