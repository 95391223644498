export enum BackendNodeType {
    DataModel = 'dbt_model',
    DataSource = 'dbt_source',
    LookerView = 'looker_view',
    LookerDerivedView = 'looker_derived_view',
    LookerExplore = 'looker_explore',
    LookerLook = 'looker_look',
    Orphan = 'generic_data_transformation',
    LookerDashboard = 'looker_dashboard',
    LookerTile = 'looker_tile',
    TableauWorkbook = 'tableau_workbook',
    TableauView = 'tableau_view',
    TableauCustomQuery = 'tableau_custom_sql',
    TableauPublishedDataSource = 'tableau_published_datasource',
    TableauEmbeddedDataSource = 'tableau_embedded_datasource',
    TableauDashboard = 'tableau_dashboard',
    TableauStory = 'tableau_story',
}

export enum BackendSubnodeType {
    Dimension = 'dimension',
    Measure = 'measure',
    Entity = 'entity',
    Metric = 'metric',
    CustomField = 'custom_field',
    Column = 'column',
    TableCalculation = 'table_calculation',
    LookerLook = 'looker_look',
    LookerConnectedView = 'looker_view',
    LookerTile = 'looker_tile',
    TableauConnectedView = 'tableau_view',
}

export type AlgoliaNode = {
    name: string;
    generated_by_delphi: boolean;
    type: BackendNodeType;
    description: string;
    tags: string[];
    database: string;
    schema: string;
    materialized: string;
    git_repo_url: string;
    git_repo_branch: string;
    package_name: string;
    dbt_project_name: string;
    unique_id: string;
    objectID: string;
    number_of_dimensions: number;
    number_of_columns?: number;
    number_of_measures: number;
    number_of_entities: number;
    number_of_metrics: number;
    number_of_custom_fields: number;
    parents: string[];
    meta: string[];
    first_seen: number;
    last_seen: number;
    proposals: boolean;
    subnodes: {
        name: string;
        description: string;
        type: BackendSubnodeType;
    }[];
    has_downstream_nodes: boolean;
    has_upstream_nodes: boolean;
    owner: string | null;
    tableau_workbook: string | null;
}

export type BackendExpandedNodeResponse = {
    name: string;
    parents: string[];
    subnodes: BackendExpandedSubnode[];
    type: BackendNodeType;
    first_seen_at: string;
    description: string;
    tags: string[] | null;
    meta: { [key: string]: string } | null;
    generated_by_delphi: boolean;
    proposals: number;
    materialized: string;
    last_30d_views: number;
    last_7d_views: number;
    last_accessed_at: string;
    favourite_count: number;
    url: string;
    git_repo_url: string;
    git_repo_branch: string;
    updated_at: string;
    updated_by: string;
    created_at: string;
    created_by: string;
    package_name: string;
    database: string;
    database_schema: string;
    utl: string;
    parent_name: string;
    allow_promote: boolean;
    raw_code: string;
    compiled_code: string;
    semantic_code: string;
    source_directory: string | null;
    looker_model: string | null;
    looker_folder: string | null;
    looker_project: string | null;
    proposal_ids: number[];
    looker_queries?: ExternalNodeUsage | null;
    dbt_project: string | null;
    owner: {
        email: string | null
    } | null;
    tableau_workbook: string | null;
    euno_project_id: number | null;
    native_updated_by: {
        email: string | null
    } | null,
    native_created_by: {
        email: string | null
    } | null,
    table_schema: {
        columns: {
            name: string,
            description: string,
            normalized_data_type: string,
            native_tags: string[],
            native_meta: { [key: string]: string }
            semantic_role: BackendSubnodeType,
            native_code: {
                code: string | null
            } | null
        }[] | null
    } | null,
    contained_resources: {
        uri: string,
        name: string,
        normalized_type: BackendNodeType
    }[] | null;
    container_chain: {
        uri: string,
        name: string,
        normalized_type: BackendNodeType
    }[] | null;
    table_properties: {
        view_native_code: {
            code: string | null
        } | null
    } | null
}

export type BackendExpandedSubnode = {
    name: string,
    description: string,
    type: string,
    subnode_type: BackendSubnodeType,
    tags: string[],
    meta: { [key: string]: string },
    parent_name: string,
    proposal_ids: number[] | null,
    source_code: string | null,
    compiled_code: string | null,
    utl: string | null,
    looker_queries: ExternalNodeUsage | null;
    last_30d_views: number | null;
    last_7d_views: number | null;
    filter: string | null;
    alias: string | null;
};

export type ExternalNodeUsage = {
    total_queries_14d: number;
    breakdown_by_user: {
        user: {
            user_email: string,
            user_name: string
        },
        cnt: number
    }[];
    breakdown_by_app: {
        app: {
            title: string | null,
            dashboard_id: null,
            dashboard_element_id: null,
            dashboard_element_title: string | null,
            type: NodeUsageAppType,
            utl: string | null
        },
        cnt: number
    }[];
}

export enum NodeUsageAppType {
    look = 'look',
    dashboard_element = 'dashboard_element',
    api_query = 'api_query',
    explore = 'explore',
    other = 'other'
}

export type BackendSuperficialNode = {
    utl: string;
    name: string;
    type: BackendNodeType;
    parent_name: string;
    parents: string[];
    generated_by_delphi: boolean;
    has_downstream_nodes: boolean;
    has_upstream_nodes: boolean;
};

export type MetricDependenciesResponse = {
    entity_name: string;
    entity_path: string | null;
    dimensions: {
        name: string;
        type: 'categorical' | 'time';
    }[];
}[];