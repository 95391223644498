import { useArchiveBranchMutation } from '../../services/branches';
import { notify } from '../../components/Toaster';
import { ButtonTypes } from '../../components/button/types';
import Modal from '../../components/Modal/Modal';
import IBranch from './IBranch';
import { extractErrorMessage } from '../../services/api';

interface DisconnectBranchModalProps {
  isOpen: boolean;
  onClose: () => void;
  branch: IBranch;
}

const DisconnectBranchModal = ({ isOpen, onClose, branch }: DisconnectBranchModalProps) => {
  const [archiveBranch, { isLoading }] = useArchiveBranchMutation();

  const onArchiveClick = async () => {
    try {
      await archiveBranch({ id: branch.id });
      notify('Branch disconnected', 'success');
      onClose();
    } catch (e) {
      notify(`Error disconnecting branch: ${extractErrorMessage(e).message}`, 'error');
      console.error(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Disconnect branch"
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.danger, text: 'Disconnect branch', onClick: onArchiveClick, isLoading }
      ]}
    >
      Are you sure would like to disconnect the branch <span className="font-semibold">{branch.name}</span>?
    </Modal>
  );
};

export default DisconnectBranchModal;
