
import { IChange, NewColumnChangeData } from '../../IChange';
import { ImplicationNode } from '../../formSections/ImplicationNode';
import { NodeType, SubnodeType } from '../../../models/discover/INode';
import { SparklesLeftArrowIcon } from '../../../../assets/images/icons/DelphiIcons';

export const Implications = ({ proposal }: { proposal: IChange }) => {
  const changeData = proposal.changeData as NewColumnChangeData;
  return (
    <div className="flex justify-between gap-2 flex-1">
      <ImplicationNode nodeWidth={350} nodeName={changeData.modelId} subNodeName={changeData.newColumnName} nodeType={NodeType.DataModel} subNodeType={SubnodeType.Column} isNew={true} />
      <div className="self-center text-tertiary">
        <SparklesLeftArrowIcon width="22" height="22" fill="#0047FF"/>
      </div>
      <ImplicationNode utl={proposal.sourceUtl} nodeWidth={350} nodeName={proposal.sourceName} subNodeName={changeData.customFieldInDataApplication} nodeType={NodeType.LookerView} subNodeType={SubnodeType.Column} isNew={false} />
    </div>
  );
};