import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import TopBar from "../../components/layout/TopBar";
import Textarea from "../../components/form/Textarea";
import Button from "../../components/button/Button";
import { ButtonTypes } from "../../components/button/types";
import { useGetLiquidConstsQuery, useUpdateLiquidConstsMutation } from "../../services/accounts";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../infrastructure/state/slices/activeAccountSlice";
import { notify } from "../../components/Toaster";
import { useUser } from "@descope/react-sdk";
import { useNavigate } from "react-router-dom";
import Select from "../../components/form/Select";
import { useGetLookerIntegrationsQuery } from "../../services/integrations/integrations";
const allowedEmailSuffix = ['@euno.ai'];

const defaultLiquidConsts = {
    "model_overrides": {},
    "project_overrides": {}
};

export const InternalTools = () => {
    const [liquidConsts, setLiquidConsts] = useState<string>("");
    const accountId = useSelector(selectActiveAccountId);
    const getLookerIntegrations = useGetLookerIntegrationsQuery({ accountId });
    const getLiquidConsts = useGetLiquidConstsQuery({ accountId, lookerIntegrationId: getLookerIntegrations.data?.[0].id || 0 }, { skip: !getLookerIntegrations.data?.[0].id });
    const [updateLiquidConsts, { isLoading: isLoadingUpdateLiquidConsts }] = useUpdateLiquidConstsMutation();
    const [selectedLookerIntegrationId, setSelectedLookerIntegrationId] = useState<number>(0);
    const { user } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (getLookerIntegrations.data && !selectedLookerIntegrationId) {
            setSelectedLookerIntegrationId(getLookerIntegrations.data[0].id);
        }
    }, [getLookerIntegrations.data, selectedLookerIntegrationId]);

    useEffect(() => {
        if (user && !allowedEmailSuffix.some(suffix => user.email?.endsWith(suffix))) {
            notify('You are not authorized to access this page', 'error');
            navigate('/');
        }
    }, [user, navigate]);

    useEffect(() => {
        if (getLiquidConsts.data) {
            setLiquidConsts(JSON.stringify(getLiquidConsts.data, null, 2));
        }
    }, [getLiquidConsts.data]);

    const onUpdateLiquidConstsClick = async () => {
        try {
            JSON.parse(liquidConsts);
        } catch (e) {
            notify('Invalid JSON', 'error');
        }
        try {
            await updateLiquidConsts({ accountId, lookerIntegrationId: getLookerIntegrations.data?.[0].id || 0, liquidConsts: JSON.parse(liquidConsts) });
            notify('Liquid consts updated', 'success');
        } catch (e) {
            notify('Failed to update liquid consts', 'error');
            console.error(e);
        }
    };

    const reset = () => {
        setLiquidConsts(JSON.stringify(defaultLiquidConsts, null, 2));
    };

    return (
        <Layout>
            <TopBar />
            <div className="mx-24 px-16 py-8 flex flex-col">
                <div className="flex-1 h-full">
                    <div className="text-xl text-text-primary">Internal tools</div>
                    <div className="flex gap-3 mt-4">
                        <InputField label="Liquid consts">
                            <div className="w-[400px] flex flex-col gap-4">
                                <Select
                                    options={getLookerIntegrations.data?.map(integration => ({ value: integration.id, label: integration.host })) || []}
                                    value={selectedLookerIntegrationId}
                                    onChange={option => setSelectedLookerIntegrationId((option as { value: number }).value)}
                                />
                                <Textarea rows={4} value={liquidConsts} onChange={e => setLiquidConsts(e.target.value)} />
                                <div className="flex gap-2 w-fit ml-auto">
                                    <Button type={ButtonTypes.secondary} text="Reset" className="mt-auto" onClick={reset} />
                                    <Button isLoading={isLoadingUpdateLiquidConsts || getLookerIntegrations.isLoading} type={ButtonTypes.primary} text="Update" className="mt-auto" onClick={onUpdateLiquidConstsClick} />
                                </div>
                            </div>
                        </InputField>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const InputField = ({ children, label }: { children: React.ReactNode; label: string; }) => {
    return (
        <div className="flex justify-between text-text-primary">
            <div className="w-32">{label}</div>
            <div>{children}</div>
        </div>
    );
};