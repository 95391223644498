import { UserIcon } from '@heroicons/react/24/solid';
import Button from '../../../../components/button/Button';
import { ButtonTypes } from '../../../../components/button/types';
import { ChangeEvent, useState } from 'react';
import Input from '../../../../components/form/Input';
import { FilterPanelProps } from '../Types';

const OwnerFilterPanel = ({ filters, setFilters }: FilterPanelProps) => {
  const [owner, setOwner] = useState(filters.owner);
  const onClear = () => {
    setOwner('');
    save();
  };

  const save = () => {
    setFilters({ ...filters, owner });
  };

  return (
    <div className="p-2">
      <Input
        placeholder="Enter owner name"
        onInputChange={(e: ChangeEvent<HTMLInputElement>) => setOwner(e.target.value)}
        value={owner}
        icon={<UserIcon width="16" height="16" className="text-slate-400" />}
        height="h-8"
      />
      <div className="mt-3 flex justify-between gap-1">
        <Button text="Clear" type={ButtonTypes.secondary} onClick={onClear} className="w-24" />
        <Button text="Apply" type={ButtonTypes.primary} onClick={save} className="w-24" />
      </div>
    </div>
  );
};

export default OwnerFilterPanel;
