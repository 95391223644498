import { NodeType, Swimlane } from "../../INode";
import { ExpandedNode, IDAGNode } from "./types";

export const generateOrphanNodes = (nodes: IDAGNode[], expandedNodes: ExpandedNode[], withFilters: boolean): IDAGNode[] => {
    const parentIdsOfViewsAndModels = [...new Set(nodes.filter(n => [NodeType.DataModel, NodeType.LookerView].includes(n.type)).flatMap(n => n.parents))];
    const parentsWithoutNodes = parentIdsOfViewsAndModels.filter(parent => !nodes.some(node => node.id === parent));
    if (withFilters) {
        removeNonExpandedOrphans(parentsWithoutNodes, expandedNodes, nodes);
    }
    const generatedOrphanNodes = parentsWithoutNodes.map(parent => {
        const parentParsed = parent.split('.');
        const name = parentParsed.pop() || '';
        const databaseSchema = parentParsed.pop() || '';
        const database = parentParsed.pop() || '';
        return {
            id: parent,
            name,
            swimlane: Swimlane.transformation,
            parents: [],
            type: NodeType.Orphan,
            subnodes: [],
            database,
            databaseSchema,
            isConnectedNode: withFilters,
            hasDownstreamNodes: false,
            hasUpstreamNodes: false,
            owner: null
        };
    });
    return generatedOrphanNodes;
};

const removeNonExpandedOrphans = (parentsWithoutNodes: string[], expandedNodes: ExpandedNode[], nodes: IDAGNode[]) => {
    for (let i = 0; i < parentsWithoutNodes.length; i++) {
        const parent = parentsWithoutNodes[i];
        const downstreamNodeIds = getDownstreamNodes(parent, nodes).map(n => n.id);
        const hasFullyExpandedChildren = downstreamNodeIds.some(childNodeId => expandedNodes.some(expandedNode => expandedNode.nodeId === childNodeId && expandedNode.direction === 'upstream' && expandedNode.depth === null));
        const hasDirectlyExpandedChild = expandedNodes.some(expandedNode => nodes.find(n => n.id === expandedNode.nodeId)?.parents.includes(parent) && expandedNode.direction === 'upstream' && expandedNode.depth === 1);
        if (!hasFullyExpandedChildren && !hasDirectlyExpandedChild) {
            parentsWithoutNodes.splice(i, 1);
            i--;
        }
    }
};

const getDownstreamNodes = (nodeId: string, nodes: IDAGNode[]): IDAGNode[] => {
    const downstreamNodes = nodes.filter(n => n.parents.includes(nodeId));
    return [
        ...downstreamNodes,
        ...downstreamNodes.flatMap(n => getDownstreamNodes(n.id, nodes))
    ];
};