import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { useState, useMemo, useEffect, ChangeEvent } from "react";
import { BranchIcon } from "../../../assets/images/icons/DelphiIcons";
import Modal from "../../../components/Modal/Modal";
import { notify } from "../../../components/Toaster";
import { ButtonTypes } from "../../../components/button/types";
import Textarea from "../../../components/form/Textarea";
import { trackEvent, events } from "../../../infrastructure/analytics";
import { BackendErrorType, extractErrorMessage } from "../../../services/api";
import { useCreateChangeMutation } from "../../../services/changes/changes";
import { useGetProjectBranchesQuery, useGetProjectQuery } from "../../../services/projects/projects";
import { ChangeType } from "../IChange";
import { NewAggregateTableChangeData } from "../changeForms/newAggregateTable/types";
import { NewAggregateTableForm } from "../changeForms/newAggregateTable/components/NewAggregateTableForm";
import { CreateChange } from "./types";
import Input from "../../../components/form/Input";
import Select, { Option } from "../../../components/form/Select";
import { onBeforeSubmitAggregateTableChange } from "../changeForms/newAggregateTable/utilities/onBeforeSubmitAggregateTableChange";
import { validateNewAggregateTableChange } from "../changeForms/newAggregateTable/utilities/validateNewAggregateTableChange";
import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { CompilationError } from "../CompilationError";
import { useDefaultTargetBranch } from "../../../services/changes/hooks";

interface CreateChangeSidepaneProps {
  isOpen: boolean;
  onClose: () => void;
  change: CreateChange;
}

const CreateChangeSidepane = ({ isOpen, onClose, change }: CreateChangeSidepaneProps) => {
  const [title, setTitle] = useState(change.title);
  const [description, setDescription] = useState(change.description);
  const [changeData, setChangeData] = useState(change.changeData);
  const [changeType, setChangeType] = useState(change.changeType);
  const getProjectBranchesQuery = useGetProjectBranchesQuery({ id: change.projectId });
  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(change.projectId);

  const branchOptions = useMemo(
    () => (getProjectBranchesQuery?.data || []).map((b) => ({ label: b.name, value: b.name })),
    [getProjectBranchesQuery]
  );
  const [targetBranch, setTargetBranch] = useDefaultTargetBranch(project, branchOptions);
  const [createChange] = useCreateChangeMutation();
  const [isCreatingChange, setIsCreatingChange] = useState(false);
  const [compilationError, setCompilationError] = useState('');
  const navigate = useNavigate();
  const isLoading = isLoadingProject || isCreatingChange;

  useEffect(() => {
    setTitle(change.title);
    setDescription(change.description);
    setChangeData(change.changeData);
    setChangeType(change.changeType);
  }, [change]);

  const onConfirm = async () => {
    setIsCreatingChange(true);
    const metaWithoutEmptyKeys = changeData.meta.filter(({ key }) => key);
    const updatedChange = { ...change, title, description, targetBranch, changeData: { ...changeData, meta: metaWithoutEmptyKeys } };
    const changeTypeSpecificEnrichment = enrichChangePerType(updatedChange);
    const changeTypeSpecificValidationError: string | null = validateChangePerType(changeTypeSpecificEnrichment);
    const changeValidationError: string | null = validateChange(updatedChange);
    if (changeTypeSpecificValidationError) {
      notify(changeTypeSpecificValidationError, 'error');
    }
    else if (changeValidationError) {
      notify(changeValidationError, 'error');
    }
    else {
      try {
        trackEvent(events.dataModelChangeEdited, { change: changeTypeSpecificEnrichment });
        const createdChange = await createChange({ projectId: change.projectId, change: changeTypeSpecificEnrichment }).unwrap();
        notify('Change draft created successfully', 'success');
        onClose();
        navigate(`/project/${change.projectId}/evolution/change/${createdChange.id}`);
      } catch (e) {
        const extractedError = extractErrorMessage(e);
        if (extractedError.type === BackendErrorType.MetricFlow) {
          setCompilationError(extractedError.message);
        }
        else {
          notify(`Error creating change: ${extractedError.message}`, 'error');
        }
      }
    }
    setIsCreatingChange(false);
  };

  return (
    <Modal containerClassName="m-0" withHeader={false} maxWidth='max-w-4xl' title='New proposal' buttons={[]} isOpen={isOpen} onClose={onClose}>
      <div className="px-4 my-4 overflow-y-auto max-h-[75vh] flex flex-col gap-2">
        <div className="flex items-center gap-1 text-surface-primary text-sm"><DocumentTextIcon width="14" height="14" /> PROPOSAL</div>
        <div className="text-sm text-tertiary">Delphi has suggested the following change</div>
        <div className="flex gap-2 items-center">
          <div className="text-secondary text-sm font-medium w-24">Change title</div>
          <div className="flex-1">
            <Input
              value={title}
              onInputChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              placeholder="Enter title"
              className="font-medium"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="text-secondary text-sm font-medium w-24">Description</div>
          <div className="flex-1">
            <Textarea
              value={description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
              placeholder="Enter description"
              className=""
              rows={4}
            />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="text-secondary text-sm font-medium w-24">Target branch</div>
          <div className="flex-1">
            <Select
              icon={<BranchIcon width="16" height="16" className="ml-2 mr-1" fill="#94A3B8" />}
              className="w-full rounded border shadow"
              options={branchOptions}
              value={targetBranch}
              onChange={(option: Option | Option[]) => setTargetBranch((option as Option).value as string)}
            />
          </div>
        </div>
        {
          changeType === ChangeType.NewAggregateTable && (
            <NewAggregateTableForm
              proposal={change}
              changeData={changeData as NewAggregateTableChangeData}
              setChangeData={setChangeData}
              setCompilationError={setCompilationError}
            />
          )
        }
      </div>
      <div className="flex items-center mt-4 m-4">
        <CompilationError error={compilationError} />
        <div className="flex gap-4 ml-auto">
          <Button
            type={ButtonTypes.primary}
            text="Create change draft"
            onClick={onConfirm}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

const enrichChangePerType = (change: CreateChange): CreateChange => {
  switch (change.changeType) {
    case ChangeType.NewAggregateTable:
      return onBeforeSubmitAggregateTableChange(change, change.changeData as NewAggregateTableChangeData);
    default:
      return change;
  }
};

const validateChangePerType = (change: CreateChange): string | null => {
  switch (change.changeType) {
    case ChangeType.NewAggregateTable:
      return validateNewAggregateTableChange(change.changeData as NewAggregateTableChangeData);
    default:
      return null;
  }
};

const validateChange = (change: CreateChange): string | null => {
  if (!change.title) {
    return 'Title is required';
  }
  if (!change.description) {
    return 'Description is required';
  }
  if (!change.targetBranch) {
    return 'Target branch is required';
  }
  return null;
};

export default CreateChangeSidepane;
