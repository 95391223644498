import { DiscoverFilterProps, SelectFilterOption } from "../Types";
import Button from "../../../../../../components/button/Button";
import { ButtonTypes } from "../../../../../../components/button/types";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../../../../components/form/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { TextWithEllipsisAndTooltip } from "../../../../../../components/TextWithEllipsisAndTooltip";

type SelectFilterProps = DiscoverFilterProps & {
    label: string;
    options: SelectFilterOption[];
    isMulti: boolean;
    withSearch?: boolean;
    withInMemorySearch?: boolean;
    onQueryChange?: (query: string) => void;
}

export const SelectFilter = ({ value, onSave, onClose, label, options, isMulti, withSearch = true, withInMemorySearch = true, onQueryChange }: SelectFilterProps) => {
    const [selected, setSelected] = useState<string[]>(value?.split(',') || []);
    const [query, setQuery] = useState<string>('');
    
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (onQueryChange) {
            onQueryChange(e.target.value);
        }
    };
    const onSelection = (selection: string) => {
        if (isMulti) {
            if (selected.includes(selection)) {
                setSelected(selected.filter(s => s !== selection));
            } else {
                setSelected([...selected, selection]);
            }
        } else {
            setSelected([selection]);
        }
    };
    const apply = useCallback(() => {
        onSave(selected.length > 0 ? selected.join(',') : null);
        onClose();
    }, [onClose, onSave, selected]);
    
    const clear = () => {
        onSave(null);
        onClose();
    };

    //On enter press
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                apply();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [apply]);

    const filteredOptions = useMemo(() => {
        if (withSearch && withInMemorySearch) {
            return options.filter(option => !query || option.label.toLowerCase().includes(query.toLowerCase()));
        }
        else {
            return options;
        }
    }, [options, query, withSearch, withInMemorySearch]);

    if (!options.length && withInMemorySearch) return <EmptyState label={label} onClose={onClose} />;

    return (
        <div className="w-64 p-2">
            {
                withSearch ? (
                    <Input
                        placeholder={label}
                        value={query}
                        onInputChange={onInputChange}
                        icon={<MagnifyingGlassIcon width={14} height={14} className="text-slate-400" />}
                        className="w-full"
                        focus={true}
                    />
                ) : <div className="text-text-primary mb-2">{label}</div>
            }
            <div className="p-2 flex flex-col max-h-[40vh] overflow-auto">
                {
                    filteredOptions.map(option => (
                        <div key={option.value} className="flex items-center justify-between bg-white hover:bg-slate-50 py-1 cursor-pointer" onClick={() => onSelection(option.value)}>
                            <div className="flex gap-2 items-center">
                                {
                                    option.icon && <div>{option.icon}</div>
                                }
                                <div className="text-text-primary"><TextWithEllipsisAndTooltip maxChars={25} text={option.label} /></div>
                            </div>
                            {selected.includes(option.value) && (
                                <div><CheckIcon width={16} height={16} className="text-surface-primary" /></div>
                            )}
                        </div>
                    ))
                }
            </div>
            <div className="flex gap-1 mt-5">
                <Button onClick={clear} type={ButtonTypes.secondary} text='Clear' className="w-full" />
                <Button onClick={apply} type={ButtonTypes.primary} text='Apply' className="w-full" />
            </div>
        </div>
    );
};

const EmptyState = ({ label, onClose }: { label: string, onClose: () => void }) => (
    <div className="w-60 p-2">
        <div className="text-text-primary mb-2">{label}</div>
        <div className="text-text-secondary text-sm mb-2">No options available</div>
        <div className="flex gap-1 mt-5">
            <Button onClick={onClose} type={ButtonTypes.secondary} text='Close' className="w-full" />
        </div>
    </div>
);