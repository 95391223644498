import { ChartBarIcon, EyeIcon } from "@heroicons/react/24/outline";
import { IExpandedNode, INodeUsage, ISourceNodeUsage, ISubnode } from "../INode";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { LookerColorlessIcon } from "../../../../assets/images/icons/DelphiIcons";
import { TextWithEllipsisAndTooltip } from "../../../../components/TextWithEllipsisAndTooltip";
import { getNodeUrl } from "../getNodeUrl";
import { NodeUsageAppType } from "../../../../services/nodes/types";
import { events, trackEvent } from "../../../../infrastructure/analytics";

export const NodeUsage = ({ node }: { node: IExpandedNode | ISubnode }) => {
    const hasViews = node.last7DaysViews !== null || node.last30DaysViews !== null;
    const hasUsage = node.usage !== null;
    if (!hasViews && !hasUsage) return null;
    return (
        <div className="mt-4 p-2 rounded bg-surface-light flex flex-col gap-2 text-sm border border-border">
            {
                hasViews && <Views last7DaysViews={node.last7DaysViews} last30DaysViews={node.last30DaysViews} />
            }
            {
                hasViews && hasUsage && <div className="w-full h-[1px] bg-border"></div>
            }
            {
                hasUsage && <Usage node={node} />
            }
        </div>
    );
};

const Views = ({ last7DaysViews, last30DaysViews }: { last7DaysViews: number | null, last30DaysViews: number | null }) => {
    return (
        <div className="flex gap-4">
            <div className="flex gap-1 items-center">
                <EyeIcon width="14" height="14" className="text-text-primary mr-1" />
                <div className="text-text-primary text-base">{last7DaysViews || 0} views</div>
                <div className="text-tertiary text-xs mt-0.5">7D</div>
            </div>
            <div className="flex gap-1 items-center">
                <div className="text-text-primary text-base">{last30DaysViews || 0} views</div>
                <div className="text-tertiary text-xs mt-0.5">30D</div>
            </div>
        </div>
    );
};

const Usage = ({ node }: { node: IExpandedNode | ISubnode }) => {
    const [showExpandedUsage, setShowExpandedUsage] = useState<boolean>(false);
    const toggleExpand = () => {
        if (!showExpandedUsage) {
            trackEvent(events.nodeUsageExpanded, { node_type: node.type });
        }
        setShowExpandedUsage(!showExpandedUsage);
    };
    if (!node.usage) return null;
    return (
        <div>
            <div className="flex justify-between cursor-pointer" onClick={toggleExpand}>
                <div className="flex gap-1 items-center">
                    <ChartBarIcon width="14" height="14" className="text-text-primary mr-1" />
                    <div className="text-text-primary text-base">{node.usage.usage14Days} uses</div>
                    <div className="text-tertiary text-xs mt-0.5">14D</div>
                </div>
                <div className="w-fit text-slate-400 bg-slate-100 hover:text-slate-500 p-0.5 border rounded border-border">
                    {
                        showExpandedUsage ? <ChevronDownIcon width="14" height="14" /> : <ChevronUpIcon width="14" height="14" />
                    }
                </div>
            </div>
            <ExpandedUsage show={showExpandedUsage} usage={node.usage} />
        </div>
    );
};

const ExpandedUsage = ({ show, usage }: { show: boolean, usage: INodeUsage }) => {
    if (!show) return null;
    const withUsers = usage.users.length > 0;
    return (
        <div className="flex flex-col justify-between mt-2 gap-2 text-xs">
            <div className="flex-1">
                <div className="text-tertiary text-sm">TOP 5 QUERIED SOURCES</div>
                <div className="flex flex-col gap-2 mt-2">
                    {
                        usage.sources.slice(0, 5).map(source => (
                            <Source key={`${JSON.stringify(source)}`} source={source} />
                        ))
                    }
                </div>
            </div>
            {
                withUsers && (
                    <div className="flex-1 border-t border-border pt-2">
                        <div className="text-tertiary text-sm">TOP LOOKER USERS</div>
                        <div className="flex flex-col gap-2 mt-2">
                            {
                                usage.users.slice(0, 5).map(user => (
                                    <User key={user.name} user={user} />
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const Source = ({ source }: { source: ISourceNodeUsage }) => {
    const goToSource = () => {
        if (source.utl) {
            window.location.href = getNodeUrl(source.utl);
        }
    };
    let title = source.title || 'Unknown';
    let subtitle = sourceTypeMap[source.type] || 'Unknown';
    if (source.dashboardTitle) {
        subtitle = `${subtitle}: ${source.dashboardTitle || 'Unknown'}`;
    }
    if (source.type === NodeUsageAppType.explore) {
        title = 'Explore';
        subtitle = '';
    }
    return (
        <div className="flex gap-1 items-center">
            <div className="bg-surface-light border rounded border-border h-fit">
                <LookerColorlessIcon fill="#94a3b8" width="12" height="12" />
            </div>
            <div className={`text-sm ${source.utl ? 'cursor-pointer text-text-primary' : 'text-slate-400 italic'}`} onClick={goToSource}><TextWithEllipsisAndTooltip text={title} maxChars={20} /></div>
            <div className="text-tertiary"><TextWithEllipsisAndTooltip text={subtitle} maxChars={25} /></div>
            <div className="text-tertiary ml-auto">{source.number}</div>
        </div>
    );
};

const sourceTypeMap = {
    [NodeUsageAppType.look]: 'Look',
    [NodeUsageAppType.dashboard_element]: 'Tile',
    [NodeUsageAppType.api_query]: 'API',
    [NodeUsageAppType.explore]: 'Explore',
    [NodeUsageAppType.other]: 'Unknown'
};

const User = ({ user }: { user: { name: string, number: number, email: string } }) => {
    return (
        <div className="flex gap-1 items-center">
            <div className="text-sm text-text-primary"><TextWithEllipsisAndTooltip text={user.name} maxChars={35} /></div>
            <div className="text-tertiary">{user.email}</div>
            <div className="text-tertiary ml-auto">{user.number}</div>
        </div>
    );
};