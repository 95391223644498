import IIntegrationKey from "../../features/account/IIntegrationKey";
import { IGithubIntegration, ILookerIntegration, IMetabaseIntegration, IDbtCloudIntegration } from "../../features/account/integrations/types";
import { Operation } from "../../features/operations/Operation";
import api from "../api";
import { GetIngtegrationKeysResponse, GetGithubIntegrationResponse, CreateLookerIntegrationRequest, CreateMetabaseIntegrationRequest, GenericIntegration, GenericIntegrationConfiguration, GenericIntegrationType } from "./types";

export const accountsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIntegrationKeys: build.query<IIntegrationKey[], number>({
      query: (accountId) => `accounts/${accountId}/integrations/keys`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetIngtegrationKeysResponse[]) => {
        return response.map((integrationKey) => ({
          name: integrationKey.name,
          id: integrationKey.id,
          createdAt: integrationKey.created_at,
          createdBy: integrationKey.created_by,
          lastUsedAt: integrationKey.last_used_at
        }));
      }
    }),
    createIntegrationKey: build.mutation<void, { accountId: number; name: string; key: string }>({
      query: ({ accountId, name, key }) => ({
        url: `/accounts/${accountId}/integrations/keys?override_secret=${key}`,
        method: 'POST',
        body: {
          name
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    revokeIntegrationKey: build.mutation<void, { accountId: number; integrationKeyId: number }>({
      query: ({ accountId, integrationKeyId }) => ({
        url: `/accounts/${accountId}/integrations/keys/${integrationKeyId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getGithubIntegrations: build.query<IGithubIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations/github`,
      providesTags: ['Integrations'],
      transformResponse: (response: GetGithubIntegrationResponse) => {
        return response.integrations.map((integration) => ({
          id: integration.installation_id,
          login: integration.account_login,
          githubAccountId: integration.account_id
        }));
      },
      keepUnusedDataFor: 0
    }),
    createGithubIntegration: build.mutation<{ url: string }, { accountId: number; redirectUrl: string }>({
      query: ({ accountId, redirectUrl }) => ({
        url: `/accounts/${accountId}/integrations/github?redirect_url=${encodeURIComponent(redirectUrl)}`,
        method: 'POST'
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkGithubIntegration: build.mutation<void, { accountId: number; githubAccountId: number }>({
      query: ({ accountId, githubAccountId }) => ({
        url: `/accounts/${accountId}/integrations/github/${githubAccountId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getLookerIntegrations: build.query<ILookerIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations/looker`,
      providesTags: ['Integrations'],
      transformResponse: (response: [{ id: number; looker_host: string; looker_client_id: string }]) => {
        return response.map((integration) => ({
          id: integration.id,
          host: integration.looker_host,
          clientId: integration.looker_client_id
        }));
      },
      keepUnusedDataFor: 0
    }),
    createLookerIntegration: build.mutation<void, CreateLookerIntegrationRequest>({
      query: ({ accountId, clientId, clientSecret, lookerHost }) => ({
        url: `/accounts/${accountId}/integrations/looker`,
        method: 'POST',
        body: {
          client_id: clientId,
          client_secret: clientSecret,
          looker_host: lookerHost
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkLookerIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/looker?looker_integration_id=${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getMetabaseIntegrations: build.query<IMetabaseIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations/metabase`,
      providesTags: ['Integrations'],
      transformResponse: (response: [{ id: number; metabase_host: string; metabase_user: string }]) => {
        return response.map((integration) => ({
          id: integration.id,
          host: integration.metabase_host,
          user: integration.metabase_user
        }));
      }
    }),
    createMetabaseIntegration: build.mutation<void, CreateMetabaseIntegrationRequest>({
      query: ({ accountId, user, password, host }) => ({
        url: `/accounts/${accountId}/integrations/metabase`,
        method: 'POST',
        body: {
          metabase_user: user,
          metabase_password: password,
          metabase_host: host
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkMetabaseIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/metabase/${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getDbtCloudIntegrations: build.query<IDbtCloudIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations/dbt_cloud`,
      providesTags: ['Integrations'],
      transformResponse: (response: [{ id: number; dbt_cloud_account_id: number; dbt_cloud_account_name: string }]) => {
        return response.map((integration) => ({
          id: integration.id,
          accountId: integration.dbt_cloud_account_id,
          accountName: integration.dbt_cloud_account_name
        }));
      },
      keepUnusedDataFor: 0
    }),
    createDbtCloudIntegration: build.mutation<void, { accountId: number; token: string; dbtAccountId: number }>({
      query: ({ accountId, dbtAccountId, token }) => ({
        url: `/accounts/${accountId}/integrations/dbt_cloud`,
        method: 'POST',
        body: {
          token,
          account_id: dbtAccountId
        }
      }),
      invalidatesTags: ['Integrations']
    }),
    unlinkDbtCloudIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/dbt_cloud/${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    getGenericIntegrations: build.query<GenericIntegration[], { accountId: number }>({
      query: ({ accountId }) => `accounts/${accountId}/integrations`,
      providesTags: ['Integrations']
    }),
    getGenericIntegration: build.query<GenericIntegration, { accountId: number; integrationName: string }>({
      query: ({ accountId, integrationName }) => `accounts/${accountId}/integrations/${integrationName}`,
      providesTags: ['Integrations']
    }),
    createGenericIntegration: build.mutation<void, { accountId: number; integrationName: string; configuration: GenericIntegrationConfiguration, integrationType: GenericIntegrationType }>({
      query: ({ accountId, integrationName, configuration, integrationType }) => ({
        url: `/accounts/${accountId}/integrations`,
        method: 'POST',
        body: {configuration, integration_type: integrationType, name: integrationName}
      }),
      invalidatesTags: ['Integrations']
    }),
    updateGenericIntegration: build.mutation<void, { accountId: number; integrationId: number; configuration: GenericIntegrationConfiguration }>({
      query: ({ accountId, integrationId, configuration }) => ({
        url: `/accounts/${accountId}/integrations/${integrationId}`,
        method: 'PATCH',
        body: configuration
      }),
      invalidatesTags: ['Integrations']
    }),
    deleteGenericIntegration: build.mutation<void, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/${integrationId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Integrations']
    }),
    crawlIntegration: build.mutation<Operation, { accountId: number; integrationId: number }>({
      query: ({ accountId, integrationId }) => ({
        url: `/accounts/${accountId}/integrations/${integrationId}/crawl`,
        method: 'POST'
      }),
      invalidatesTags: ['Integrations']
    })
  })
});

export const {
  useGetIntegrationKeysQuery,
  useCreateIntegrationKeyMutation,
  useRevokeIntegrationKeyMutation,
  useGetGithubIntegrationsQuery,
  useCreateGithubIntegrationMutation,
  useUnlinkGithubIntegrationMutation,
  useGetLookerIntegrationsQuery,
  useCreateLookerIntegrationMutation,
  useUnlinkLookerIntegrationMutation,
  useCreateMetabaseIntegrationMutation,
  useGetMetabaseIntegrationsQuery,
  useUnlinkMetabaseIntegrationMutation,
  useCreateDbtCloudIntegrationMutation,
  useGetDbtCloudIntegrationsQuery,
  useUnlinkDbtCloudIntegrationMutation,
  useGetGenericIntegrationsQuery,
  useGetGenericIntegrationQuery,
  useCreateGenericIntegrationMutation,
  useUpdateGenericIntegrationMutation,
  useDeleteGenericIntegrationMutation,
  useCrawlIntegrationMutation
} = accountsApi;
