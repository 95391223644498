import { useEffect, useState } from "react";
import Table from "../../../../../components/Table/Table";
import { ISuperficialNode } from "../../INode";
import { discoverTableColumns } from "./discoverTableColumns";
import { useGetAlgoliaCredentialsQuery } from "../../../../../services/accounts";
import { useSelector } from "react-redux";
import { notify } from "../../../../../components/Toaster";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { getNodes } from "../../../../../services/nodes/nodes";
import PageLoader from "../../../../../components/loaders/PageLoader";
const defaultPageSize = 25;

type DiscoverTableViewProps = {
    setShowNodeSidepane: (node: ISuperficialNode) => void;
    setSelectedNode: (node: ISuperficialNode) => void;
    filterString: string;
}

export const DiscoverTableView = ({ setShowNodeSidepane, setSelectedNode, filterString }: DiscoverTableViewProps) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const accountId = useSelector(selectActiveAccountId);
    const getAlgoliaCredentialsQuery = useGetAlgoliaCredentialsQuery({ accountId });
    const [nodes, setNodes] = useState<ISuperficialNode[]>([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      if (!getAlgoliaCredentialsQuery.data) return;
      const { key, app_id, index_name } = getAlgoliaCredentialsQuery.data;
      getNodes({ apiKey: key, applicationId: app_id, indexName: index_name, page: page - 1, pageSize, filters: filterString })
        .then(({total, items}) => {{
            setNodes(items);
            setTotal(total);
            setIsLoading(false);
        }})
        .catch(e => {
          console.error(e);
          notify('Failed to load nodes', 'error');
        });
    }, [getAlgoliaCredentialsQuery, page, pageSize, filterString]);

    const onRowClicked = (row: unknown) => {
        setSelectedNode(row as ISuperficialNode);
        setShowNodeSidepane(row as ISuperficialNode);
    };

    if (isLoading) return <PageLoader />;
    
    return (
        <div className="mt-5 mx-3">
            <Table
                data={nodes} 
                columns={discoverTableColumns}
                onRowClicked={onRowClicked}
                pagination={{ page, pageSize, total, setPage, setPageSize }}
                maxBodyHeight='80vh'
                className="max-w-[85vw]"
            />
        </div>
    );
};