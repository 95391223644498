import { Filter } from './Types';
import { DocumentDuplicateIcon, EyeIcon, LanguageIcon, ServerStackIcon, Squares2X2Icon, TableCellsIcon, TagIcon, CodeBracketIcon, BriefcaseIcon, FolderIcon, ViewfinderCircleIcon, ChartBarIcon, UserIcon } from '@heroicons/react/24/outline';
import { mapBackendNodeTypeToLocalNodeType } from '../../../../../services/nodes/transformers';
import { NodeType, nodeNameMap } from '../../INode';
import { SelectFilter } from './filterComponents/SelectFilter';
import { DbtCloudIcon, LookerInstanceIcon, ModelIcon, SourceDirectoryIcon, SparklesLeftArrowIcon, TableauIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { MetaFilter } from './MetaFilter';
import { NodeIcon } from '../../NodeIcon';
import { BackendNodeType } from '../../../../../services/nodes/types';
import { NameFilter } from './NameFilter';
import { FreeTextFilter } from './filterComponents/FreeTextFilter';

type FilterValue = string | null;

export const discoverFilterList: Filter[] = [
  {
    name: 'Type',
    menuIcon: <Squares2X2Icon width="20" height="20" className="mr-1" />,
    filterIcon: <Squares2X2Icon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value?.split(',').map(v => nodeNameMap.get(mapBackendNodeTypeToLocalNodeType.get(v as BackendNodeType)!)).join(',') || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `type:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Type"
        options={filterOptions?.type?.map((type) => ({ label: nodeNameMap.get(mapBackendNodeTypeToLocalNodeType.get(type as BackendNodeType)!) || '', value: type, icon: <NodeIcon iconSize={16} type={mapBackendNodeTypeToLocalNodeType.get(type as BackendNodeType) as NodeType} /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Name',
    menuIcon: <LanguageIcon width="20" height="20" className="mr-1" />,
    filterIcon: <LanguageIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => `${value}`,
    isDisabled: false,
    component: NameFilter,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `name:"${v}"`).join(' OR ')})` : '',
  },
  {
    name: 'Proposals',
    menuIcon: <SparklesLeftArrowIcon width="18" height="18" className="mr-1.5" fill="#64748B" />,
    filterIcon: <SparklesLeftArrowIcon width="16" height="16" fill="#56A5FF" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => Object.entries(proposalsOptions).find((entry) => entry[1] === value)?.[0] || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `proposals${value}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Proposals"
        options={Object.entries(proposalsOptions).map(([label, value]) => ({ label, value, icon: <SparklesLeftArrowIcon width="16" height="16" fill="#64748B" /> }))}
        isMulti={false}
        withSearch={false}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Total views 7D',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    filterIcon: <EyeIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => totalViewsOptions.find(({ value: v }) => v === value)?.label || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `total_views_7d${value}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total views 7D"
        options={totalViewsOptions.map(({ label, value }) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={false}
        withSearch={false}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Total views 30D',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    filterIcon: <EyeIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => totalViewsOptions.find(({ value: v }) => v === value)?.label || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `total_views_30d${value}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total views 30D"
        options={totalViewsOptions.map(({ label, value }) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={false}
        withSearch={false}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Looker usage 14D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    filterIcon: <ChartBarIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => usageOptions.find(({ value: v }) => v === value)?.label || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `total_queries_14d${value}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Looker usage 14D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={false}
        withSearch={false}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'First seen',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    filterIcon: <EyeIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => Object.entries(firstSeenOptions).find((entry) => entry[1] === value)?.[0] || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `first_seen:${firstSeenValueFormatter(value)}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="First seen"
        options={Object.entries(firstSeenOptions).map(([label, value]) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={false}
        withSearch={false}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Materialization',
    menuIcon: <DocumentDuplicateIcon width="20" height="20" className="mr-1" />,
    filterIcon: <DocumentDuplicateIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `materialized:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Materialization"
        options={filterOptions?.materialized?.map((materialized) => ({ label: materialized, value: materialized, icon: <DocumentDuplicateIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Tags',
    menuIcon: <TagIcon width="20" height="20" className="mr-1" />,
    filterIcon: <TagIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `tags:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tags"
        options={filterOptions?.tags?.map((tag) => ({ label: tag, value: tag, icon: <TagIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Meta',
    menuIcon: <CodeBracketIcon width="20" height="20" className="mr-1" />,
    filterIcon: <TagIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `meta:"${v}"`).join(' OR ')})` : '',
    component: MetaFilter
  },
  {
    name: 'dbt project',
    menuIcon: <DbtCloudIcon width="18" height="18" className="mr-1.5" fill="#64748B" />,
    filterIcon: <DbtCloudIcon width="16" height="16" fill="#56A5FF" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `dbt_project_name:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="dbt project"
        options={filterOptions?.dbt_project_name?.map((dbt_project_name) => ({ label: dbt_project_name, value: dbt_project_name, icon: <DbtCloudIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Database schema',
    menuIcon: <TableCellsIcon width="20" height="20" className="mr-1" />,
    filterIcon: <TableCellsIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `database_schema:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Database schema"
        options={filterOptions?.database_schema?.map((schema) => ({ label: schema, value: schema, icon: <TableCellsIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Database',
    menuIcon: <ServerStackIcon width="20" height="20" className="mr-1" />,
    filterIcon: <ServerStackIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `database:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Database"
        options={filterOptions?.database?.map((database) => ({ label: database, value: database, icon: <ServerStackIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Looker project',
    menuIcon: <BriefcaseIcon width="20" height="20" className="mr-1" />,
    filterIcon: <BriefcaseIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `looker_project:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker project"
        options={filterOptions?.looker_project?.map((looker_project) => ({ label: looker_project, value: looker_project, icon: <BriefcaseIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Looker folder',
    menuIcon: <FolderIcon width="20" height="20" className="mr-1" />,
    filterIcon: <FolderIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `looker_folder:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker folder"
        options={filterOptions?.looker_folder?.map((looker_folder) => ({ label: looker_folder, value: looker_folder, icon: <FolderIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Looker model',
    menuIcon: <ModelIcon width="20" height="20" className="mr-1" fill="#64748B" />,
    filterIcon: <ModelIcon width="16" height="16" fill="#56A5FF" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `looker_model:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker model"
        options={filterOptions?.looker_model?.map((looker_model) => ({ label: looker_model, value: looker_model, icon: <ModelIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Looker instance',
    menuIcon: <LookerInstanceIcon width="18" height="18" className="mr-1.5" fill="#475569" />,
    filterIcon: <LookerInstanceIcon width="16" height="16" fill="#56A5FF" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `looker_host:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker instance"
        options={filterOptions?.looker_host?.map((looker_host) => ({ label: looker_host, value: looker_host, icon: <LookerInstanceIcon width="16" height="16" fill="#475569" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Source directory',
    menuIcon: <SourceDirectoryIcon width="20" height="20" className="mr-1" fill="#64748B" />,
    filterIcon: <SourceDirectoryIcon width="16" height="16" fill="#56A5FF" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `source_directory:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Source directory"
        options={filterOptions?.source_directory?.map((source_directory) => ({ label: source_directory, value: source_directory, icon: <SourceDirectoryIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'UTL',
    menuIcon: <ViewfinderCircleIcon width="20" height="20" className="mr-1" />,
    filterIcon: <ViewfinderCircleIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `objectID:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <FreeTextFilter
        value={value}
        onChange={onChange}
        onClose={onClose}
        onSave={onSave}
        placeholder={'UTL'}
        label={'UTL'}
      />
  },
  {
    name: 'Tableau project',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    filterIcon: <TableauIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `tableau_project:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau project"
        options={filterOptions?.tableau_project?.map((tableau_project) => ({ label: tableau_project, value: tableau_project, icon: <TableauIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Tableau workbook',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    filterIcon: <TableauIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `tableau_workbook:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau workbook"
        options={filterOptions?.tableau_workbook?.map((tableau_workbook) => ({ label: tableau_workbook, value: tableau_workbook, icon: <TableauIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Owner',
    menuIcon: <UserIcon width="20" height="20" className="mr-1" />,
    filterIcon: <UserIcon width="16" height="16" className="text-muse-400" />,
    isActive: (value: FilterValue) => !!value,
    value: null,
    presentValue: (value: FilterValue) => value || '',
    isDisabled: false,
    getFilterString: (value: FilterValue) => value ? `(${value.split(',').map(v => `owner:"${v}"`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Owner"
        options={filterOptions?.owner?.map((owner) => ({ label: owner, value: owner, icon: <UserIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
];

const firstSeenOptions = {
  'Last 7 days': 'last_7_days',
  'Last 30 days': 'last_30_days',
  'Last 90 days': 'last_90_days',
};

const firstSeenValueFormatter = (value: string) => {
  const end = Math.round(Date.now() / 1000);
  let start: number;
  switch (value) {
    case 'last_7_days':
      start = end - 7 * 24 * 60 * 60;
      break;
    case 'last_30_days':
      start = end - 30 * 24 * 60 * 60;
      break;
    case 'last_90_days':
      start = end - 90 * 24 * 60 * 60;
      break;
    default:
      start = end;
  }
  return [start, end].join(' TO ');
};

const totalViewsOptions = [
  { label: '0-10', value: ': 0 TO 10' },
  { label: '11-100', value: ': 11 TO 100' },
  { label: '101-1,000', value: ': 101 TO 1000' },
  { label: '1,001-10,000', value: ': 1001 TO 10000' },
  { label: '10,001+', value: ' > 10000' },
];

const proposalsOptions = {
  'Has a proposal': ' > 0',
  'Doesn\'t have a proposal': ' = 0',
};

const usageOptions = [
  { label: '0', value: ' = 0' },
  { label: '1-100', value: ': 1 TO 100' },
  { label: '101-500', value: ': 101 TO 500' },
  { label: '501-1,000', value: ': 501 TO 1000' },
  { label: '1,001-5,000', value: ': 1001 TO 5000' },
  { label: '5,001-10,000', value: ': 5001 TO 10000' },
  { label: '10,001+', value: ' > 10000' },
];