import { NodeSidepane } from "../NodeSidepane";
import { IExpandedNode } from "../../../INode";
import { buttonsWithWorkbook } from "../buttonsWithWorkbook";

type TableauViewSidepaneProps = {
    node: IExpandedNode;
    setCanCloseModal: (canClose: boolean) => void;
}

export const TableauViewSidepane = ({ node, setCanCloseModal }: TableauViewSidepaneProps) => {
    return <NodeSidepane
        node={node}
        setCanCloseModal={setCanCloseModal}
        ButtonsComponent={buttonsWithWorkbook(node)}
    />;
};