import api from './api';
import IRepository from '../features/projects/IRepository';

interface GetRepositoriesResponse {
  repos: {
    id: number;
    name: string;
    full_name: string;
    private: boolean;
    owner_id: number;
    owner_type: string;
    owner_login: string;
    clone_url: string;
  }[];
}

export interface User {
  email: string;
  user_id: number;
  github_login: string;
}

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRepositories: build.query<IRepository[], void>({
      query: () => 'user/repos',
      transformResponse: (response: GetRepositoriesResponse) => {
        return response?.repos.map((repo) => ({
          id: repo.id,
          name: repo.name,
          ownerId: repo.owner_id.toString(),
          cloneUrl: repo.clone_url,
          ownerLogin: repo.owner_login,
          private: repo.private,
          ownerType: repo.owner_type
        }));
      }
    }),
    getUser: build.query<User, void>({
      query: () => 'user',
      providesTags: ['Users']
    })
  })
});

export const { useGetUserQuery, useGetRepositoriesQuery } = usersApi;