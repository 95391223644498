import { NodeIcon } from '../../NodeIcon';
import { ISuperficialNode, nodeNameMap } from '../../INode';
import { TableColumnProps } from '../../../../../components/Table/types';
import { DescriptionColumn } from './DescriptionColumn';
import { SparklesLeftArrowIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { TextWithEllipsisAndTooltip } from '../../../../../components/TextWithEllipsisAndTooltip';
export const discoverTableColumns: TableColumnProps[] = [
  {
    name: 'Type',
    selector: row => <div data-tag="allowRowEvents" className="flex gap-1 items-center"><NodeIcon type={(row as ISuperficialNode).type} /> {nodeNameMap.get((row as ISuperficialNode).type)}</div>,
    width: '200px'
  },
  {
    name: 'Name',
    selector: row => <TextWithEllipsisAndTooltip text={(row as ISuperficialNode).name} maxChars={40} />,
    grow: 10
  },
  {
    name: 'Description',
    selector: row => <DescriptionColumn row={row as ISuperficialNode} />,
    width: '300px'
  },
  {
    name: 'Columns',
    selector: row => (row as ISuperficialNode).numberOfColumns,
    width: '100px'
  },
  {
    name: 'Dimensions',
    selector: row => (row as ISuperficialNode).numberOfDimensions,
    width: '110px'
  },
  {
    name: 'Measures',
    selector: row => (row as ISuperficialNode).numberOfMeasures,
    width: '100px'
  },
  {
    name: 'Entities',
    selector: row => (row as ISuperficialNode).numberOfEntities,
    width: '100px'
  },
  {
    name: 'Related Metrics',
    selector: row => (row as ISuperficialNode).numberOfMetrics,
    width: '130px'
  },
  {
    name: 'Custom Fields',
    selector: row => (row as ISuperficialNode).numberOfCustomFields,
    width: '130px'
  },
  {
    name: 'Proposals',
    selector: row => (row as ISuperficialNode).proposals ? <SparklesLeftArrowIcon width="16" height="16" fill='#0047FF' data-tag="allowRowEvents"/> : '',
  }
];