import { Bars3Icon, CircleStackIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { DbtCloudIcon, DelphiIcon, DbtDimensionIcon, EntityIcon, LookerIcon, MeasureIcon, MetricIcon, TableauIcon } from "../../../assets/images/icons/DelphiIcons";
import { NodeType, SubnodeType } from "./INode";

type NodeIconProps = {
    type: NodeType;
    generatedByDelphi?: boolean;
    iconSize?: number;
    withPadding?: boolean;
    withBackground?: boolean;
    subnodeType?: SubnodeType;
    greyVersion?: boolean;
}

const greyVersionColor = "#64748B";

export const NodeIcon = ({ type, subnodeType, generatedByDelphi = false, iconSize = 14, withPadding = false, withBackground = false, greyVersion = false }: NodeIconProps) => {

    const TypeToMap = {
        [NodeType.DataModel]: <DbtCloudIcon width={iconSize} height={iconSize} fill={greyVersion ? greyVersionColor : "#FF694A"} />,
        [NodeType.DataSource]: <CircleStackIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerExplore]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerLook]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerView]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerDerivedView]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.Orphan]: <TableCellsIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerDashboard]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.LookerTile]: <LookerIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauWorkbook]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauView]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauCustomQuery]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauDashboard]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauEmbeddedDataSource]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauPublishedDataSource]: <TableauIcon width={iconSize} height={iconSize} />,
        [NodeType.TableauStory]: <TableauIcon width={iconSize} height={iconSize} />,
    };

    const generatedByDelphiClass = withPadding ? 'rounded-t py-1 px-2' : 'rounded-t';
    const notGeneratedByDelphiClass = withPadding ? 'rounded p-2' : 'rounded';
    const backgroundClass = withBackground ? 'bg-surface-light' : '';
    const icon = subnodeType ? getSubnodeIcon(type, subnodeType, iconSize) : TypeToMap[type];

    return (
        <div>
            <div className={`w-fit ${backgroundClass} ${generatedByDelphi ? generatedByDelphiClass : notGeneratedByDelphiClass}`}>
                {icon}
            </div>
            {
                generatedByDelphi && (
                    <div className="rounded-b bg-muse-400">
                        <DelphiIcon width="10" height="10" fill="white" className="mx-auto" />
                    </div>
                )
            }
        </div>
    );
};

const getSubnodeIcon = (type: NodeType, subnodeType: SubnodeType, iconSize: number) => {
    const SubnodeTypeToMap = {
        [SubnodeType.LookerConnectedView]: <LookerIcon width={iconSize} height={iconSize} />,
        [SubnodeType.LookerTile]: <LookerIcon width={iconSize} height={iconSize} />,
        [SubnodeType.LookerLook]: <LookerIcon width={iconSize} height={iconSize} />,
        [SubnodeType.Column]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Dimension]: <Bars3Icon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Measure]: <MeasureIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.CustomField]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Schema]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Metric]: <MetricIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.Entity]: <EntityIcon fill={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.TableCalculation]: <Bars3Icon color={greyVersionColor} width={iconSize} height={iconSize} />,
        [SubnodeType.TableauConnectedView]: <TableauIcon width={iconSize} height={iconSize} />,
    };
    const TypeSpecificMap = new Map([
        [NodeType.DataModel, new Map([
            [SubnodeType.Dimension, <DbtDimensionIcon key='ModelDimension' fill="#FF694A" width={iconSize} height={iconSize} />],
        ])],
        [NodeType.DataSource, new Map([
            [SubnodeType.Dimension, <DbtDimensionIcon key='DataSourceDimension' fill="#FF694A" width={iconSize} height={iconSize} />],
        ])],
    ]);
    const typeSpecificIcon = TypeSpecificMap.get(type)?.get(subnodeType);
    if (typeSpecificIcon) {
        return typeSpecificIcon;
    }
    return SubnodeTypeToMap[subnodeType];
};