import api from './api';
import IBranch from '../features/branches/IBranch';

interface GetRepositoryBranchesRequest {
  organization: string;
  repository: string;
}

export const repositoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRepositoryBranches: build.query<IBranch[], GetRepositoryBranchesRequest>({
      query: (data) => `/repos/${data.organization}/${data.repository}/branches`
    })
  })
});

export const { useGetRepositoryBranchesQuery } = repositoriesApi;
