import { store } from "../../../../../infrastructure/state/store";
import { getNodes, nodesApi } from "../../../../../services/nodes/nodes";
import { isNodeHiddenFromDag } from "../../INode";
import { maxNodesForDag } from "./DAGConfiguration";
import { ExpandedNode, IDAGNode } from "./types";

type GetNodesForDAGProps = {
    key: string;
    app_id: string;
    index_name: string;
    filterString: string;
}

export const getDAGNodes = async ({ key, app_id, index_name, filterString }: GetNodesForDAGProps): Promise<IDAGNode[]> => {
    const originalNodes = await getOriginalNodes({ key, app_id, index_name, filterString });
    const distinctNodes = originalNodes.filter((node, index, self) => self.findIndex(n => n.id === node.id) === index);
    return distinctNodes.map(n => ({ ...n, isConnectedNode: false }));
};

export const getConnectedNodes = async ({ accountId, expandedNodes }: { accountId: number, expandedNodes: ExpandedNode[] }): Promise<IDAGNode[]> => {
    const nodes = await Promise.all(expandedNodes.map(async (expandedNode) => {
        const { data } = await store.dispatch(nodesApi.endpoints.getConnectedSuperficialNodes.initiate({
            accountId,
            nodeIds: [expandedNode.nodeId],
            limit: Number.MAX_SAFE_INTEGER,
            direction: expandedNode.direction,
            depth: expandedNode.depth
        }));
        return data;
    }));
    return nodes.flat().filter(Boolean).map(n => ({ ...n, isConnectedNode: true }) as IDAGNode);
};

type GetOriginalNodesProps = {
    key: string;
    app_id: string;
    index_name: string;
    filterString: string;
}

const getOriginalNodes =  async ({ key, app_id, index_name, filterString }: GetOriginalNodesProps) => {
    const { items: originalNodes } = await getNodes({
        apiKey: key,
        applicationId: app_id,
        indexName: index_name,
        pageSize: maxNodesForDag + 1,
        filters: filterString
    });
    return originalNodes.filter(n => !isNodeHiddenFromDag(n));
};