import { Option } from '../../../components/form/Select';
import { CheckCircleIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import Select from '../../../components/form/Select';
import Checkbox from '../../../components/form/Checkbox';
import Input from '../../../components/form/Input';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ChangeRelationship, ColumnTests } from '../IChange';
import { ISuperficialNode, NodeType } from '../../models/discover/INode';
import { getNodes } from '../../../services/nodes/nodes';
import { useGetAlgoliaCredentialsQuery } from '../../../services/accounts';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { mapNodeTypeToBackendNodeType } from '../../../services/nodes/transformers';

type NewColumnTestsProps = {
  tests: ColumnTests;
  setTests: (tests: ColumnTests) => void;
  labelWidth?: string;
  disabled?: boolean;
};

export const ColumnTestsForm = ({ tests, setTests, labelWidth = 'w-36', disabled = false }: NewColumnTestsProps) => {
  const setIsUnique = (value: boolean) => {
    setTests({ ...tests, isUnique: value });
  };

  const setIsNullable = (value: boolean) => {
    setTests({ ...tests, isNullable: !value });
  };

  const setAcceptedValues = (values: string[]) => {
    setTests({ ...tests, acceptedValues: values });
  };

  const removeAcceptedValue = (index: number) => {
    const newAcceptedValues = [...tests.acceptedValues];
    newAcceptedValues.splice(index, 1);
    setAcceptedValues(newAcceptedValues);
  };

  return (
    <div className="flex">
      <div className={`text-secondary self-baseline text-sm font-medium flex items-center gap-1 ${labelWidth}`}>
        <CheckCircleIcon width="12" height="12" />
        Column Tests
      </div>
      <div className="flex flex-col gap-2 flex-1">
        <Checkbox value={tests.isUnique} setValue={setIsUnique} label="Unique" disabled={disabled} />
        <Checkbox value={!tests.isNullable} setValue={setIsNullable} label="Not Null" disabled={disabled} />
        <Checkbox
          value={tests.acceptedValues.length > 0}
          setValue={(value) => setAcceptedValues(value ? [''] : [])}
          label="Accepted Values"
          disabled={disabled}
        />
        {tests.acceptedValues.map((acceptedValueItem, index) => (
          <div key={index} className="flex items-center gap-1">
            <Input
              value={acceptedValueItem}
              onInputChange={(e: ChangeEvent<HTMLInputElement>) => {
                const newAcceptedValues = [...tests.acceptedValues];
                newAcceptedValues[index] = e.target.value;
                setAcceptedValues(newAcceptedValues);
              }}
              placeholder="Accepted Value"
              height="h-9"
              disabled={disabled}
            />
            {
              !disabled && (
                <div className="flex w-12 gap-1">
                  <MinusCircleIcon
                    height="18"
                    width="18"
                    className="cursor-pointer text-slate-300 hover:text-slate-400"
                    onClick={() => removeAcceptedValue(index)}
                  />
                  {index === tests.acceptedValues.length - 1 && (
                    <PlusCircleIcon
                      height="18"
                      width="18"
                      className="cursor-pointer text-slate-400 hover:text-slate-500"
                      onClick={() => setAcceptedValues([...tests.acceptedValues, ''])}
                    />
                  )}
                </div>
              )
            }
          </div>
        ))}
        <Relationships tests={tests} setTests={setTests} disabled={disabled}/>
      </div>
    </div>
  );
};

const Relationships = ({ tests, setTests, disabled }: NewColumnTestsProps) => {
  const [models, setModels] = useState<ISuperficialNode[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const accountId = useSelector(selectActiveAccountId);
  const getAlgoliaCredentialsQuery = useGetAlgoliaCredentialsQuery({ accountId });
  useEffect(() => {
    if (tests.relationships !== null && models.length === 0 && getAlgoliaCredentialsQuery.data) {
      const { key, app_id, index_name } = getAlgoliaCredentialsQuery.data;
      getModelNodes(key, app_id, index_name).then(models => {
        setModels(models);
        setIsLoading(false);
      });
    }
  }, [tests.relationships, getAlgoliaCredentialsQuery, models]);
  const model = tests.relationships?.model || '';
  const dimension = tests.relationships?.dimension || '';

  const setRelationships = (relationships: ChangeRelationship | null) => {
    setTests({ ...tests, relationships });
  };

  const modelOptions = useMemo(() => models.map((model) => ({ label: model.name, value: model.identifier || '' })), [models]);

  const dimensionOptions = useMemo(() => {
    const model = models.find((model) => model.identifier === tests.relationships?.model);
    if (!model) {
      return [];
    }
    return model.subnodes.map(({ name }) => ({ label: name, value: name }));
  }, [models, tests.relationships]);

  return (
    <div >
      <Checkbox
        value={tests.relationships !== null}
        setValue={(value) => setRelationships(value ? { model: '', dimension: '' } : null)}
        label="Relationships"
        disabled={disabled}
      />
      {tests.relationships && (
        <div className="mt-2 flex items-center gap-2">
          <Select
            options={modelOptions}
            value={model}
            onChange={(option) => setRelationships({ model: (option as Option).value as string, dimension })}
            placeholder="Model"
            className="w-full rounded-md border border-gray-300 shadow"
            height="h-9"
            isLoading={isLoading}
            maxOptions={10}
            isDisabled={disabled}
          />
          <Select
            options={dimensionOptions}
            value={dimension}
            onChange={(option) => setRelationships({ model, dimension: (option as Option).value as string })}
            placeholder="Dimension"
            className="w-full rounded-md border border-gray-300 shadow"
            height="h-9"
            isLoading={isLoading}
            isDisabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

const getModelNodes = async (apiKey: string, applicationId: string, indexName: string) => {
  const { items } = await getNodes({
    query: '',
    filters: `type:"${mapNodeTypeToBackendNodeType.get(NodeType.DataModel)}"`,
    apiKey,
    applicationId,
    indexName,
    pageSize: Number.MAX_SAFE_INTEGER //Quick fix until we implement autocomplete
  });
  return items;
};
