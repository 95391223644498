import { ColumnTestsForm } from '../../formSections/ColumnTestsForm';
import { ColumnTests, IChange, NewColumnChangeData } from '../../IChange';
import { Implications } from './NewColumnImplications';
import { TagsForm } from '../../formSections/TagsForm';
import { MetaForm } from '../../formSections/MetaForm';
import { DescriptionForm } from '../../formSections/DescriptionForm';
import { NameForm } from '../../formSections/NameForm';

interface NewColumnFormProps {
  changeData: NewColumnChangeData;
  setChangeData: (changeData: NewColumnChangeData) => void;
  proposal: IChange;
}

const NewColumnForm = ({ changeData, setChangeData, proposal }: NewColumnFormProps) => {
  const setTests = (tests: ColumnTests) => setChangeData({ ...changeData, ...tests });
  return (
    <div className="mt-2 flex flex-col gap-2">
      <div className="flex items-center mb-2 gap-2">
        <div className="text-secondary text-sm font-medium w-24">Model changes</div>
        <Implications proposal={proposal} />
      </div>
      <NameForm label="Column" name={changeData.newColumnName} setName={newColumnName => setChangeData({ ...changeData, newColumnName })} />
      <DescriptionForm label="Column" description={changeData.description} setDescription={description => setChangeData({ ...changeData, description })} />
      <TagsForm label="Column" tags={changeData.tags} setTags={tags => setChangeData({ ...changeData, tags })} />
      <MetaForm label="Column" meta={changeData.meta} setMeta={meta => setChangeData({ ...changeData, meta })} />
      <ColumnTestsForm tests={changeData} setTests={setTests} />
    </div>
  );
};

export default NewColumnForm;
