import { TailSpin } from 'react-loader-spinner';
import { useState } from 'react';
import { DataModelIcon } from '../../../assets/images/icons/DelphiIcons';
import Modal from '../../../components/Modal/Modal';
import { notify } from '../../../components/Toaster';
import { ButtonTypes } from '../../../components/button/types';
import { extractErrorMessage } from '../../../services/api';
import { Operation, OperationStatus } from '../../operations/Operation';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useGetOperationLogsQuery } from '../../../services/accounts';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import Button from '../../../components/button/Button';
import waitForOperationToComplete from '../../../infrastructure/waitForOperation';
import { useCrawlIntegrationMutation, useGetGenericIntegrationsQuery } from '../../../services/integrations/integrations';
import { GenericIntegration } from '../../../services/integrations/types';
import { useNavigate } from 'react-router-dom';
import Select, { Option } from '../../../components/form/Select';

type CrawlIntegrationModalProps = {
    onClose: () => void;
    isOpen: boolean;
}

export const CrawlIntegrationModal = ({ onClose, isOpen }: CrawlIntegrationModalProps) => {
    const [crawlIntegrationMutation, { isLoading }] = useCrawlIntegrationMutation();
    const accountId = useSelector(selectActiveAccountId);
    const [operation, setOperation] = useState<Operation | null>(null);
    const [selectedIntegration, setSelectedIntegration] = useState<GenericIntegration | null>(null);

    const crawlIntegration = async () => {
        try {
            if (!selectedIntegration) throw new Error('Select integration first');
            const operation = await crawlIntegrationMutation({ accountId, integrationId: selectedIntegration.id }).unwrap();
            setOperation(operation);
            const finalOperationState = await waitForOperationToComplete(operation.id, accountId);
            setOperation(finalOperationState);
        } catch (e) {
            notify(`Failed to crawl integration: ${extractErrorMessage(e).message}`, 'error');
        }
    };

    const buttons = [];

    if (!operation) {
        buttons.push(
            {
                type: ButtonTypes.secondary,
                text: 'Cancel',
                onClick: onClose
            },
            {
                type: ButtonTypes.primary,
                text: 'Crawl',
                onClick: crawlIntegration,
                isLoading: isLoading
            }
        );
    } else if ([OperationStatus.Running, OperationStatus.Pending].includes(operation.status)) {
        buttons.push(
            {
                type: ButtonTypes.primary,
                text: 'Close',
                onClick: () => { },
                isLoading: true
            }
        );
    } else {
        buttons.push(
            {
                type: ButtonTypes.primary,
                text: 'Done',
                onClick: () => { setOperation(null); onClose(); }
            }
        );
    }

    return (
        <Modal buttons={buttons} title="Crawl integration" onClose={onClose} isOpen={isOpen}>
            <div className="mt-4 flex flex-col items-center gap-4 rounded-lg border border-slate-200 bg-surface-light p-6 text-text-primary">
                <CrawlState selectedIntegration={selectedIntegration} operation={operation} setSelectedIntegration={setSelectedIntegration} />
            </div>
        </Modal>
    );
};

const SelectIntegration = ({ setSelectedIntegration, selectedIntegration }: { setSelectedIntegration: (integration: GenericIntegration) => void, selectedIntegration: GenericIntegration | null }) => {
    const accountId = useSelector(selectActiveAccountId);
    const getGenericIntegrations = useGetGenericIntegrationsQuery({ accountId });
    const onChange = (option: Option | Option[]) => {
        const integration = getGenericIntegrations.data?.find((i) => i.id === (option as Option).value);
        if (integration) {
            setSelectedIntegration(integration);
        }
    };

    return (
        <>
            <div className="font-medium">Select integration</div>
            <Select
                options={getGenericIntegrations.data?.map(i => ({ label: i.name, value: i.id })) || []}
                value={selectedIntegration?.id || null}
                onChange={onChange}
                className="border shadow-sm w-full"
            />
        </>
    );
};

const RunningOperationBanner = () => {
    return (
        <>
            <TailSpin
                height="32"
                width="32"
                color="#0047FF"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="ml-auto mr-auto"
                visible={true}
            />
            <div className="mt-4">Crawling integration...</div>
        </>
    );
};

const FailedOperationBanner = ({ operation }: { operation: Operation }) => {
    const accountId = useSelector(selectActiveAccountId);
    const logs = useGetOperationLogsQuery({ accountId, operationId: operation.id }, { skip: !operation })?.data || '';
    return (
        <>
            <ExclamationCircleIcon width="32" height="32" className="ml-auto mr-auto text-secondary" />
            <div className="w-full">
                <div className="text-center font-medium">Something went wrong</div>
                <div className="mt-1 max-h-48 w-full overflow-auto whitespace-pre-line rounded-lg border border-slate-300 p-1 text-sm	">
                    {logs}
                </div>
            </div>
        </>
    );
};

const CompletedOperationBanner = () => {
    const navigate = useNavigate();
    return (
        <>
            <CheckCircleIcon width="32" height="32" className="ml-auto mr-auto text-surface-primary" />
            <div className="font-medium">Crawl finished successfully</div>
            <Button
                type={ButtonTypes.secondary}
                icon={<DataModelIcon width="12" height="12" fill="#0A225C" />}
                text="View in data model"
                className="mt-4"
                onClick={() => navigate('/data-model')}
            />
        </>
    );
};

const CrawlState = ({ operation, setSelectedIntegration, selectedIntegration }: { operation: Operation | null, setSelectedIntegration: (integration: GenericIntegration) => void, selectedIntegration: GenericIntegration | null }) => {
    if (!operation) {
        return <SelectIntegration selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} />;
    } else if ([OperationStatus.Running, OperationStatus.Pending].includes(operation.status)) {
        return <RunningOperationBanner />;
    } else if (operation.status === OperationStatus.Completed) {
        return <CompletedOperationBanner />;
    } else {
        return <FailedOperationBanner operation={operation} />;
    }
};