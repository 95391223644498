import { SparklesIcon } from '@heroicons/react/24/solid';
import { ChangeStatus, ChangeType, IChangePage } from '../IChange';
import dateFormat from 'dateformat';
import Table from '../../../components/Table/Table';
import { PaginationProps } from '../../../components/Table/types';
import { getEnumKeyByEnumValue } from '../../../utils/enumUtils';
import statusToColorScheme from './statusToColorScheme';
import { TextWithEllipsisAndTooltip } from '../../../components/TextWithEllipsisAndTooltip';
import { DbtCloudIcon, LookerIcon } from '../../../assets/images/icons/DelphiIcons';

interface ModelChangesListProps {
  changes: IChangePage[];
  onClick: (change: IChangePage) => void;
  pagination: PaginationProps;
}

export const ModelChangesList = ({ changes, onClick, pagination }: ModelChangesListProps) => {
  const onRowClicked = (row: unknown) => {
    onClick(row as IChangePage);
  };

  const tableColumns = [
    {
      name: '#',
      selector: (row: unknown) => `${(row as IChangePage).id}`,
      width: '80px'
    },
    {
      name: 'TYPE',
      selector: (row: unknown) => (
        <div className="flex gap-1 uppercase" data-tag="allowRowEvents">
          <div><SparklesIcon width="16" height="16" /></div><div>{(row as IChangePage).changeType}</div>
        </div>
      ),
      width: '200px'
    },
    {
      name: 'TITLE',
      selector: (row: unknown) => <span className="text-text-primary" data-tag="allowRowEvents"><TextWithEllipsisAndTooltip text={(row as IChangePage).title} maxChars={65} /></span>,
      width: 'auto'
    },
    {
      name: 'SOURCE',
      selector: SourceColumn,
      width: '250px'
    },
    {
      name: 'OWNER',
      selector: (row: unknown) => (row as IChangePage).createdBy,
      width: '200px'
    },
    {
      name: 'DATE',
      selector: (row: unknown) => dateFormat((row as IChangePage).createdAt, 'mmm dd yyyy, HH:MM TT'),
      width: '200px'
    },
    {
      name: 'STATUS',
      selector: (row: unknown) => {
        const status = (row as IChangePage).status;
        const statusLabel = getEnumKeyByEnumValue(ChangeStatus, status);
        return <div className={`rounded-full px-3 py-1 text-sm ${statusToColorScheme(status)}`} data-tag="allowRowEvents">{statusLabel}</div>;
      },
      width: '150px'
    }
  ];

  return (
    <div className="mt-4 max-w-[78vw]">
      <Table data={changes} columns={tableColumns} onRowClicked={onRowClicked} maxBodyHeight='72vh' pagination={pagination} />
    </div>
  );
};

const SourceColumn = (row: unknown) => {
  const change = row as IChangePage;
  let icon;
  switch (change.changeType) {
    case ChangeType.NewAggregateTable:
      icon = <DbtCloudIcon width="16" height="16" fill="#FF694A" />;
      break;
    default:
      icon = <LookerIcon width="16" height="16" />;
      break;
  }
  return (
    <div className="flex gap-1 text-black" data-tag="allowRowEvents">
      <div>{icon}</div>
      <TextWithEllipsisAndTooltip text={(row as IChangePage).createdByResourceName} maxChars={20} />
    </div>
  );
};